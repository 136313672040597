/* eslint-disable */
import { useEffect, useState, useRef, useContext } from "react";
import NavCandidato from "../../Components/Header/NavCandidato";
import DialogComponent from "../../Components/Dialog/Dialog";
import {
  UploadImgPefil,
  getCursos,
  getExp,
  getUser,
  ResetPasswordProfile,
  validateToken,
} from "../../actions/userActions";
import Estado from "../../Service/Estados";
import Cidade from "../../Service/Cidade";
import EditarInfoPessoais from "./Editar/EditarInfoPessoais";
import AlertComponent from "../../Components/Alert/Alert";

import { useForm, Controller } from "react-hook-form";
import { Context } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import authSearchUser from "../../Service/authSearchUser";

export default function ConfiguracoesCandidato() {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState("");

  const [token, setToken] = useState();
  const [RecallComponent, setReacall] = useState(Math.random());
  const [uploadImg, setUploadImg] = useState();

  const [message, setMessage] = useState("");
  const [isOpenMessage, setIsOpenMessage] = useState("");

  const hiddenFileInput = useRef(null);

  const escolaridade = [
    { id: "FI", nameescolaridade: "Ensino Fundamental - Incompleto" },
    { id: "FC", nameescolaridade: "Ensino Fundamental - Completo" },
    { id: "MI", nameescolaridade: "Ensino Medio - Incompleto" },
    { id: "MC", nameescolaridade: "Ensino Medio - Completo" },
    { id: "SI", nameescolaridade: "Ensino Superior - Incompleto" },
    { id: "SC", nameescolaridade: "Ensino Superior - Completo" },
  ];

  const sexo = [
    {
      id: "M",
      namesexo: "Masculino",
      unavailable: false,
    },

    { id: "F", namesexo: "Feminino", unavailable: false },
    { id: "N", namesexo: "Não informar", unavailable: false },
  ];

  const { handleLogout } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    async function userDate() {
      const token = await authSearchUser(handleLogout, navigate, validateToken);
      setToken(token);

      const getUserDate = await getUser(token);

      await setUser(getUserDate);
    }
    userDate();
  }, []);

  useEffect(() => {
    async function userDate() {
      const token = await authSearchUser(handleLogout, navigate, validateToken);

      setToken(token);

      const getUserDate = await getUser(token);

      await setUser(getUserDate);
    }
    userDate();
  }, [RecallComponent]);

  useEffect(() => {
    async function uploadImgP() {
      if (!uploadImg) {
        return;
      } else {
        const token = await authSearchUser(
          handleLogout,
          navigate,
          validateToken
        );

        const teste = await UploadImgPefil(uploadImg, token, user._id);
      }
    }
    uploadImgP();
  }, [uploadImg]);

  async function closeModal() {
    setReacall(Math.random());
    setIsOpen(false);
  }

  function openInformacoes() {
    setIsOpen(true);
  }

  function openAlertMessage(status) {
    setTimeout(() => {
      setIsOpenMessage(false);
    }, 3000);
    setIsOpenMessage(true);
  }

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });

  const submit = async (values) => {
    const isLocalStorage = JSON.parse(localStorage.getItem("_myuser_"));
    const tokenLocal = isLocalStorage.token;
    const idToken = isLocalStorage._id;
    const resp = await ResetPasswordProfile(values, idToken, tokenLocal);
    openAlertMessage();
    setMessage(resp);
    reset();
  };

  return (
    <>
      <DialogComponent
        openDialog={isOpen}
        closeModal={closeModal}
        title={"Editar Informações Pessoais"}
        component={
          <EditarInfoPessoais
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            userDate={user}
          />
        }
      />

      <div className="h-screen flex flex-col">
        <div className="flex flex-1">
          <NavCandidato />
          <main className="flex-1  bg-gray-400/10 sm:p-16 p-6 lg:block">
            <div className="grid grid-cols-1 gap-4 mt-10">
              <div className="bg-white">
                <div className="p-3">
                  <div className="flex flex-row">
                    <img
                      src="https://i.ibb.co/LP2cKrj/user.png"
                      className="w-12 h-12"
                    />
                    <p className="p-3 text-xl font-semibold">
                      Informações pessoais
                      <small className="flex text-slate-600 text-sm">
                        Aqui fica algumas informações pessoais para que o
                        recrutador avalie seu curriculo.
                      </small>
                    </p>
                    <div className="flex flex-1 justify-end">
                      <div className="justify-end content-end">
                        <button
                          onClick={openInformacoes}
                          type="button"
                          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs p-3 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                        >
                          Editar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6">
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Nome
                    </label>
                    <p className="text-sm font-semibold">{user.nome}</p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Endereço de email
                    </label>
                    <p className="text-sm font-semibold">{user.email}</p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Sexo
                    </label>
                    <p className="text-sm font-semibold">
                      {sexo.map((sexoValue) => {
                        if (sexoValue.id === user.sexo) {
                          return sexoValue.namesexo;
                        } else {
                          return "";
                        }
                      })}
                    </p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Data Nascimento
                    </label>
                    <p className="text-sm font-semibold">
                      {user.datanascimento}
                    </p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Telefone
                    </label>
                    <p className="text-sm font-semibold">{user.telefone} </p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Enderenço
                    </label>
                    <p className="text-sm font-semibold">
                      {Cidade.map((cidadeValue) => {
                        if (cidadeValue.ID == user.cidade) {
                          return cidadeValue.Nome;
                        } else {
                          return "";
                        }
                      })}
                      -
                      {Estado.map((estadoValue) => {
                        if (estadoValue.ID == user.estado) {
                          return estadoValue.Sigla;
                        } else {
                          return "";
                        }
                      })}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white">
                <div className="flex flex-row p-3">
                  <p className="p-3 flex-grow text-xl font-semibold">
                    Segurança
                  </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 p-6">
                  <form className="space-y-6" onSubmit={handleSubmit(submit)}>
                    <div>
                      <label
                        htmlFor="password_atual"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Senha Atual
                      </label>
                      <div className="mt-2">
                        <input
                          type="password"
                          id="password_atual"
                          {...register("password_atual", {
                            required: true,
                          })}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="new_password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Nova Senha
                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                          id="new_password"
                          name="new_password"
                          type="password"
                          {...register("new_password", {
                            required: true,
                          })}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="new_password_confirm"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Nova Senha novamente
                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                          id="new_password_confirm"
                          name="new_password_confirm"
                          type="password"
                          {...register("new_password_confirm", {
                            required: true,
                          })}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                      >
                        {isOpenMessage && message ? message : "Alterar senha"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
