import { useState } from "react";

import { useForm } from "react-hook-form";
import ErrorsForm from "../../../Components/FormError/Errors";

import Estado from "../../../Service/Estados";
import Cidade from "../../../Service/Cidade";
import { postvaga } from "../../../actions/companyActions";
import LoadingCompoent from "../../../Components/Loading/Loading";
import AlertComponent from "../../../Components/Alert/Alert";
import NavComp from "../../../Components/Header/NavComp";
import authSearchComp from "../../../Service/authSearchComp";

let AdicionarVagas = (props) => {
  const [valueEstado, setEstado] = useState("select");
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState();

  const [statusMessage, setStatusMessage] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  function openMessage(status) {
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
    setIsOpen(true);
  }

  const handleChange = (e) => {
    const estado = e.target.options[e.target.selectedIndex].id;
    setEstado(estado);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const faixasalarial = [
    { id: 1, value: "A Combinar" },
    { id: 2, value: "R$ 1.200,00 a R$ 1.500,00" },
    { id: 3, value: "R$ 1.500,00 a R$ 2.500,00" },
    { id: 4, value: "R$ 3.000,00 ou mais" },
  ];

  const experiencia = [
    { id: 1, value: "Sim" },
    { id: 2, value: "Não" },
  ];

  const escolaridade = [
    { id: 1, nameescolaridade: "Ensino Fundamental - Incompleto" },
    { id: 2, nameescolaridade: "Ensino Fundamental - Completo" },
    { id: 3, nameescolaridade: "Ensino Medio - Incompleto" },
    { id: 4, nameescolaridade: "Ensino Medio - Completo" },
    { id: 5, nameescolaridade: "Ensino Superior - Incompleto" },
    { id: 6, nameescolaridade: "Ensino Superior - Completo" },
  ];

  const cidade = Cidade.filter((cidade) => {
    if (cidade.Estado === valueEstado) {
      return cidade.Nome;
    } else return null;
  });

  const onSubmit = async (data) => {
    setLoading(true);

    const token = await authSearchComp(
      props.handleLogout,
      props.navigate,
      props.valid
    );
    if (token === undefined || !token) {
      return;
    }

    const resp = await postvaga(data, token);

    openMessage();
    setStatusMessage(true);
    setMessage(resp[0].message);
    reset();
    setLoading(false);
  };

  return (
    <div className="relative isolate px-3  lg:px-8">
      <div className="flex flex-col">
        <div>
          <form
            className="mx-auto max-w-xl mt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Titulo da vaga
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="titulovaga"
                    {...register("titulovaga", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.titulovaga?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  descrição da vaga
                </label>
                <div className="mt-1">
                  <textarea
                    type="text"
                    id="descricao"
                    {...register("descricao", {
                      required: true,
                    })}
                    rows={6}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.descricao?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Requisitos da vaga
                </label>
                <div className="mt-1">
                  <textarea
                    type="text"
                    id="requisitos"
                    {...register("requisitos", {
                      required: true,
                    })}
                    rows={6}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.requisitos?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Faixa salarial da vaga
                </label>
                <div className="mt-2.5">
                  <select
                    id="faixasalario"
                    {...register("faixasalario", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="select">Selecione uma faixa salarial</option>
                    {faixasalarial.map((faixa) => (
                      <>
                        <option key={faixa.id} value={faixa.id} id={faixa.id}>
                          {faixa.value}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.faixasalario?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.faixasalario?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione uma faixa salarial"} />
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Escolaridade para a vaga
                </label>
                <div className="mt-2.5">
                  <select
                    id="escolaridade"
                    {...register("escolaridade", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="select">Selecione sua escolaridade</option>
                    {escolaridade.map((escolaridade) => (
                      <>
                        <option
                          key={escolaridade.id}
                          value={escolaridade.id}
                          id={escolaridade.id}
                        >
                          {escolaridade.nameescolaridade}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.escolaridade?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.escolaridade?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione uma escolaridade"} />
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Exigir Experiencia para a vaga?
                </label>
                <div className="mt-2.5">
                  <select
                    id="experiencia"
                    {...register("experiencia", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="select">
                      Selecione se exigir experiencia para vaga
                    </option>
                    {experiencia.map((experiencia) => (
                      <>
                        <option
                          key={experiencia.id}
                          value={experiencia.id}
                          id={experiencia.id}
                        >
                          {experiencia.value}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.experiencia?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.experiencia?.type === "validate" && (
                    <ErrorsForm
                      text={"Ops! elecione se exigir experiencia para vaga"}
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Estado
                </label>
                <div className="mt-2.5">
                  <select
                    id="estado_user"
                    {...register("estado", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    onChange={handleChange}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="select">Selecione seu estado</option>
                    {Estado.map((Estado) => (
                      <>
                        <option
                          key={Estado.id}
                          id={Estado.ID}
                          value={Estado.ID}
                        >
                          {Estado.Nome} - {Estado.Sigla}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.estado?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.estado?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione um estado"} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Cidade
                </label>
                <div className="mt-2.5">
                  <select
                    id="cidade_user"
                    {...register("cidade", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option key={"select"} value="select">
                      Selecione sua cidade
                    </option>
                    {cidade.map((cidade) => (
                      <>
                        <option
                          key={cidade.id}
                          value={cidade.ID}
                          id={cidade.ID}
                        >
                          {cidade.Nome}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.cidade?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.cidade?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione uma cidade"} />
                  )}
                </div>
              </div>
            </div>

            <div className="mt-10 mb-10">
              {isOpen ? (
                <AlertComponent message={message} status={statusMessage} />
              ) : (
                <button
                  type="submit"
                  className="block w-full rounded-md bg-blue-me px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-me focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-me"
                >
                  {loading ? <LoadingCompoent /> : "Adicionar Vaga"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdicionarVagas;
