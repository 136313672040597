import { X } from "lucide-react";

export default function ModalVagas({ open, onClose, children }) {
  return (
    // backdrop
    <div
      onClick={onClose}
      className={`
          fixed inset-0 flex justify-center items-center transition-colors
          ${open ? "visible bg-black/20" : "invisible"}
        `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
            bg-white rounded-xl shadow p-6 transition-all
            ${
              open ? "scale-75 sm:scale-100 opacity-100" : "scale-125 opacity-0"
            }
          `}
      >
        <button
          onClick={onClose}
          className="absolute top-2 sm:top-2 right-2 p-1 rounded-lg text-white
          bg-red-600 hover:bg-red-500 hover:text-white"
        >
          <X />
        </button>
        {children}
      </div>
    </div>
  );
}
