import axios from "axios";
import authorization from "../auth/authRouter";

const BASE_URL = process.env.REACT_APP_URL_ADMIN;

export async function getVagasCompany(token) {
  const headers = authorization(token);
  const response = await axios.get(
    `${BASE_URL}/1cf0d8f39ac0ca7d92db0001bb13d890`,
    {
      headers: headers,
    }
  );

  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function getDatesCompany(token, idComp) {
  const headers = authorization(token);
  const response = await axios.get(
    `${BASE_URL}/1cf0d8f39ac0ca7d92db0001bb13d890`,
    {
      headers: headers,
    }
  );

  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

///alterarstatuscomp/:compId/:value

export async function putStatusVaga(value, id, token) {
  const headers = authorization(token);
  const response = await axios
    .put(`${BASE_URL}/alterarstatus/${id}/${value}`, {
      headers: headers,
    })
    .then((resp) => {
      return resp.data[0].message;
    })
    .catch((e) => {
      return e;
    });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response;
  }
}
