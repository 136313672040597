/* eslint-disable */
import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Book,
  List,
  Mail,
  LogOut,
  UserCircle,
  UserCircle2,
  ClipboardList,
  Trophy,
  Medal,
  Crown,
  Edit,
  PlusSquare,
  Briefcase,
  Delete,
  FolderX,
} from "lucide-react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import NavCandidato from "../../Components/Header/NavCandidato";

const navigation = [{ name: "Quem somos?", href: "#" }];

export default function Notificacao() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="h-screen flex flex-col">
        <div className="flex flex-1">
          <NavCandidato />
          <main className="flex-1 bg-gray-400/10 p-16">
            <div>
              <div>
                <p className="text-xl font-semibold text-center justify-center">
                  Estamos nos organizando para que voce possa ter ainda mais
                  controle com notificações de vagas, entre outras coisas
                  aguarde, em breve novidades.
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
