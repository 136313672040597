export default function LoadingCompoent(props) {
  return (
    <>
      <div
        className="h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent  justify-center text-center text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </>
  );
}
