export default async function authSearchUser(
  handleLogout,
  navigate,
  validateToken
) {
  const isLocalStorage = JSON.parse(localStorage.getItem("_myuser_"));
  if (!isLocalStorage) {
    const user = 1;
    handleLogout(user);
    navigate("/login");
  } else {
    const token = isLocalStorage.token;
    const x = await validateToken(token);
    if (!x) {
      const user = 1;
      handleLogout(user);
      navigate("/login");
      return;
    }
    return token;
  }
}
