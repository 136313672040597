import { useContext, useState } from "react";

import Estado from "../../../Service/Estados";
import Cidade from "../../../Service/Cidade";
import { useForm } from "react-hook-form";
import ErrorsForm from "../../../Components/FormError/Errors";
import InputMask from "react-input-mask";
import AlertComponent from "../../../Components/Alert/Alert";

import { useEffect } from "react";

import LoadingCompoent from "../../../Components/Loading/Loading";
import { updateCompany } from "../../../actions/companyActions";
import {
  getComp,
  listvagacomp,
  validateToken,
} from "../../../actions/companyActions";
import NavComp from "../../../Components/Header/NavComp";
import authSearchComp from "../../../Service/authSearchComp";
import { Context } from "../../../context/auth";
import { useNavigate } from "react-router-dom";

let EditarInfoEmpresa = (props) => {
  const [valueEstado, setEstado] = useState(String("1") || "select");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);

  const [cnpj, setCnpj] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [comp, setComp] = useState("");
  const [token, setToken] = useState();
  const [vagasAprovadas, setVagasAprovadas] = useState();

  const { handleLogout } = useContext(Context);
  const navigate = useNavigate();

  const cidade = Cidade.filter((cidade) => {
    if (cidade.Estado === valueEstado) {
      return cidade.Nome;
    } else return null;
  });

  //Mask input
  const handlePhone = (event) => {
    let input = event.target;

    input.value = phoneMask(input.value);
  };
  const phoneMask = (value) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  };

  const handleChange = (e) => {
    const estado = e.target.options[e.target.selectedIndex].id;
    setEstado(estado);
  };

  useEffect(() => {
    async function compDate() {
      setLoading(true);
      const token = await authSearchComp(handleLogout, navigate, validateToken);
      if (token === undefined || !token) {
        return;
      }

      setToken(token);

      const getCompDate = await getComp(token);
      const getVagasAprovadas = await listvagacomp(token);

      await setComp(getCompDate);
      await setVagasAprovadas(getVagasAprovadas);
      await setEstado(getCompDate.estado);
      await setCnpj(getCompDate.cnpj);
      setLoading(false);
    }
    compDate();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });

  function openMessage(status) {
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
    setIsOpen(true);
  }

  const onSubmit = async (data) => {
    setLoading(true);
    const isLocalStorage = JSON.parse(localStorage.getItem("_mycomp_"));
    const tokenLocal = isLocalStorage.token;

    const resp = await updateCompany(data, tokenLocal);
    openMessage();
    setStatusMessage(true);
    setMessage(resp[0].message);
    setLoading(false);
  };
  console.log(comp.cidade);

  setValue("nome", comp.nome);
  setValue("email", comp.email);
  setValue("telefone", comp.telefone);
  setValue("endereco", comp.endereco);
  setValue("cnpj", comp.cnpj);
  setValue("estado", comp.estado);
  setValue("cidade", comp.cidade);
  async function editForm() {
    //const { nome, email, telefone, endereco, cnpj, estado, cidade } = comp;
    console.log("comp >> ", comp.cidade);
    /* setValue("nome", nome);
    setValue("email", email);
    setValue("telefone", telefone);
    setValue("endereco", endereco);
    setValue("cnpj", cnpj);
    setValue("estado", estado);
    setValue("cidade", cidade);*/
  }

  function onChangeCnpj(e) {
    //setCnpj("");
    setCnpj(e.target.value);

    /*{...register("cnpj", {
      minLength: 14,
      required: true,
      validate: (value) => {
        const pt1 = value.slice(0, 2);
        const pt2 = value.slice(3, 6);
        const pt3 = value.slice(7, 10);
        const pt4 = value.slice(11, 15);
        const pt5 = value.slice(16, 18);
        const numb = parseInt(`${pt1}${pt2}${pt3}${pt4}${pt5}`);
        const x = numb.toString().length;
        return x >= 14;
      },
    })}*/
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-1">
        <NavComp />
        <main className="flex-1 bg-gray-400/10 p-4">
          <AlertComponent
            message={
              "Caso precise de ajuda e/ou suporte, entre em contato conosco atraves do nosso email suporte@meemprega.com.br"
            }
          />
          <p className="text-justify text-2xl font-semibold">
            Olá, {comp.nome}
          </p>
          <p>
            Altere os dados da sua empresa, as alteração ira ocorrer
            imediatamente.
          </p>
          <div className="grid grid-cols-1 gap-4 mt-10 bg-white p-4 rounded-2xl">
            {loading ? (
              <LoadingCompoent />
            ) : (
              <div>
                <div className="relative isolate px-3 pt-2 lg:px-8">
                  <div className="pt-5 flex flex-col">
                    <form
                      className="max-w-full"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                          <label className="block text-sm font-semibold leading-6 text-gray-900">
                            Nome Empresa
                          </label>
                          <div className="mt-2.5">
                            <input
                              id="name_user"
                              name="nome"
                              {...register("nome", {
                                minLength: 2,
                                required: true,
                              })}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors?.nome?.type === "required" && (
                              <ErrorsForm text={"Ops! Faltou algo aqui."} />
                            )}
                            {errors?.nome?.type === "minLength" && (
                              <ErrorsForm
                                text={"Nome precisa ter pelo menos 2 letras."}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-semibold leading-6 text-gray-900">
                            Cnpj
                          </label>
                          <div className="mt-2.5">
                            <InputMask
                              mask="99.999.999/9999-99"
                              value={cnpj}
                              // name="cnpj"
                              // id="cnpj"
                              {...register("cnpj", {
                                minLength: 14,
                                required: true,
                                validate: (value) => {
                                  const pt1 = value.slice(0, 2);
                                  const pt2 = value.slice(3, 6);
                                  const pt3 = value.slice(7, 10);
                                  const pt4 = value.slice(11, 15);
                                  const pt5 = value.slice(16, 18);
                                  const numb = parseInt(
                                    `${pt1}${pt2}${pt3}${pt4}${pt5}`
                                  );
                                  const x = numb.toString().length;
                                  return x >= 14;
                                },
                              })}
                              onChange={onChangeCnpj}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              {(inputProps) => (
                                <input
                                  label="Phone"
                                  variant="outlined"
                                  type="text"
                                  {...inputProps}
                                />
                              )}
                            </InputMask>
                            {errors?.cnpj?.type === "required" && (
                              <ErrorsForm text={"Ops! Faltou algo aqui."} />
                            )}
                            {errors?.cnpj?.type === "validate" && (
                              <ErrorsForm
                                text={
                                  "Ops! voce precisa ter pelo menos 14 anos."
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-semibold leading-6 text-gray-900">
                            Endereço
                          </label>
                          <div className="mt-2.5">
                            <input
                              id="data_endereco"
                              nome="endereco"
                              {...register("endereco", {
                                required: true,
                              })}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors?.endereco?.type === "required" && (
                              <ErrorsForm text={"Ops! Faltou algo aqui."} />
                            )}
                            {errors?.endereco?.type === "validate" && (
                              <ErrorsForm
                                text={
                                  "Ops! voce precisa ter pelo menos 14 anos."
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-semibold leading-6 text-gray-900">
                            Email
                          </label>
                          <div className="mt-2.5">
                            <input
                              type="email"
                              id="email_empresa"
                              {...register("email", {
                                required: true,
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email",
                                },
                              })}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors?.email?.type === "required" && (
                              <ErrorsForm text={"Ops! Faltou algo aqui."} />
                            )}

                            {errors?.email?.type === "pattern" && (
                              <ErrorsForm
                                text={"preencha o email corretamente"}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-semibold leading-6 text-gray-900">
                            Telefone
                          </label>
                          <div className="mt-2.5">
                            <input
                              onKeyUp={(e) => {
                                handlePhone(e);
                              }}
                              {...register("telefone", {
                                minLength: 14,
                                required: true,
                                validate: (value) => {
                                  const dd = value.slice(1, 3);
                                  const numberpt1 = value.slice(4, 9);
                                  const numberpt2 = value.slice(10, 14);
                                  const numb = parseInt(
                                    `${dd}${numberpt1}${numberpt2}`
                                  );
                                  const x = numb.toString().length;
                                  return x >= 11;
                                },
                              })}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                            {errors?.telefone?.type === "required" && (
                              <ErrorsForm text={"Ops! Faltou algo aqui."} />
                            )}
                            {errors?.telefone?.type === "minLength" && (
                              <ErrorsForm
                                text={
                                  "Não esqueça de adicionar o dd + seu numero"
                                }
                              />
                            )}
                            {errors?.telefone?.type === "validate" && (
                              <ErrorsForm
                                text={
                                  "Numbero incompleto nao esqueça do DD e do digito 9"
                                }
                              />
                            )}
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-semibold leading-6 text-gray-900">
                            Estado
                          </label>
                          <div className="mt-2.5">
                            <select
                              id="estado_user"
                              {...register("estado", {
                                required: true,
                                validate: (value) => {
                                  return value !== "select";
                                },
                              })}
                              onChange={handleChange}
                              className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="select">
                                Selecione seu estado
                              </option>
                              {Estado.map((Estado) => (
                                <>
                                  <option
                                    key={Estado.id}
                                    id={Estado.ID}
                                    value={Estado.ID}
                                  >
                                    {Estado.Nome} - {Estado.Sigla}
                                  </option>
                                </>
                              ))}
                            </select>
                            {errors?.estado?.type === "required" && (
                              <ErrorsForm text={"Ops! Faltou algo aqui."} />
                            )}
                            {errors?.estado?.type === "validate" && (
                              <ErrorsForm text={"Ops! Selecione um estado"} />
                            )}
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-semibold leading-6 text-gray-900">
                            Cidade
                          </label>
                          <div className="mt-2.5">
                            <select
                              id="cidade_user"
                              {...register("cidade", {
                                required: true,
                                validate: (value) => {
                                  return value !== "select";
                                },
                              })}
                              className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option key={"select"} value="select">
                                Selecione sua cidade
                              </option>
                              {Cidade.map((cidade) => (
                                <>
                                  <option
                                    key={cidade.id}
                                    value={cidade.ID}
                                    id={cidade.ID}
                                  >
                                    {cidade.Nome}
                                  </option>
                                </>
                              ))}
                            </select>
                            {errors?.cidade?.type === "required" && (
                              <ErrorsForm text={"Ops! Faltou algo aqui."} />
                            )}
                            {errors?.cidade?.type === "validate" && (
                              <ErrorsForm
                                text={"Ops! Selecione uma escolaridade"}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-10 mb-10">
                        {isOpen ? (
                          <AlertComponent
                            message={message}
                            status={statusMessage}
                          />
                        ) : (
                          <button
                            type="submit"
                            className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                          >
                            {loading ? <LoadingCompoent /> : "Editar"}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default EditarInfoEmpresa;
