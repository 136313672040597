import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Album,
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  Calendar,
  CalendarCheck,
  Coins,
  CornerDownLeft,
  CornerDownRight,
  Edit,
  Hammer,
  HandCoins,
  Mail,
} from "lucide-react";
import { useForm } from "react-hook-form";
import ErrorsForm from "../../../Components/FormError/Errors";
import InputMask from "react-input-mask";
import { reduxForm } from "redux-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { OrbitIcon } from "lucide-react";
import {
  postCandidate,
  postCurso,
  postExp,
  putCurso,
} from "../../../actions/userActions";
import Estado from "../../../Service/Estados";
import Cidade from "../../../Service/Cidade";
import DialogComponent from "../../../Components/Dialog/Dialog";
import LoadingCompoent from "../../../Components/Loading/Loading";
import { MapPin } from "lucide-react";

const BASE_URL = process.env.REACT_APP_URL_USUARIO;

function faixasalario(opc) {
  switch (opc) {
    case 1:
      return "A combinar";
    case 2:
      return "R$ 1.200,00 a R$ 1.500,00";
    case 3:
      return "R$ 1.500,00 a R$ 2.500,00";
    case 4:
      return "R$ 3.000,00 ou mais";
    default:
      return "A Combinar";
  }
}

function escolaridade(opc) {
  switch (opc) {
    case 1:
      return "Ensino Fundamental ou Medio";
    case 2:
      return "Ensino Medio Completo";
    case 3:
      return "Ensino Superior Incompleto";
    case 4:
      return "Ensino Superior Completo";
    default:
      return "A Combinar";
  }
}

function dateFormat(date) {
  const newDate = new Date(date);

  const dateformat = newDate.toLocaleDateString("pt-br");
  return dateformat;
}

let DialogVagas = (props) => {
  const [loading, setLoading] = useState(false);

  async function onSubmit(idVaga, close, open) {
    const isLocalStorage = JSON.parse(localStorage.getItem("_myuser_"));
    const tokenLocal = isLocalStorage.token;
    setLoading(true);
    await postCandidate(idVaga, tokenLocal);
    open();
    setLoading(false);
  }

  const isLocalStorage = JSON.parse(localStorage.getItem("_myuser_"));
  const idLocal = isLocalStorage._id;

  const list = props.listCandidate || [];
  const [open, setOpen] = useState(false);

  const listFilter = list.filter((l) => l.idVaga === props.dateVaga._id);

  function openDialog() {
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
    props.closeModal();
  }

  return (
    <>
      <p className="font-semibold text-xl text-slate-950 gap-4 pb-4">
        {props.dateVaga.titulovaga}
      </p>
      <div className="relative pb-16 isolate px-3  lg:px-8">
        <p className="font-semibold flex flex-row gap-1">
          <AlignJustify />
          Descrição da vaga
        </p>
        <p className="pl-7 pb-4">{props.dateVaga.descricao}</p>
        <p className="font-semibold flex flex-row gap-1">
          <AlignCenter />
          Requisitos Vaga
        </p>
        <p className="pl-7 pb-4">{props.dateVaga.requisitos}</p>
        <p className="font-semibold flex flex-col sm:flex-row gap-1 pb-4">
          <Hammer />
          Necessita de Experiencia?
          <div>
            <p className="font-normal">
              {props.dateVaga.experiencia === "1" ? "Sim" : "Não"}
            </p>
          </div>
        </p>

        <p className="font-semibold flex flex-col sm:flex-row gap-1 pb-4">
          <Edit />
          Escolaridade:
          <p className="font-normal">
            {escolaridade(props.dateVaga.escoloridade)}
          </p>
        </p>

        <p className="font-semibold flex flex-col sm:flex-row  gap-1 pb-4">
          <Coins />
          Faixa Salarial:
          <p className="font-normal">
            {faixasalario(props.dateVaga.faixasalario)}
          </p>
        </p>

        <p className="font-semibold flex flex-row gap-1">
          <MapPin />
          Cidade:
          <p className="font-normal pb-4">
            {Cidade.map((cidadeValue) => {
              if (cidadeValue.ID == props.dateVaga.cidade) {
                return cidadeValue.Nome;
              } else {
                return "";
              }
            })}
          </p>
        </p>

        <p className="font-semibold flex flex-row gap-1 ">
          <MapPin />
          Estado:
          <p className="font-normal pb-4">
            {Estado.map((cidadeValue) => {
              if (cidadeValue.ID == props.dateVaga.estado) {
                return cidadeValue.Nome;
              } else {
                return "";
              }
            })}
          </p>
        </p>
        <p className="font-semibold flex flex-row gap-1">
          <CalendarCheck />
          Data da Vaga:
          <p className="font-normal">{dateFormat(props.dateVaga.datavaga)}</p>
        </p>

        <div className="pt-4">
          {listFilter.length === 0 ? (
            <button
              onClick={() => {
                onSubmit(props.dateVaga._id, props.closeModal, openDialog);
              }}
              className=" 
           py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0
"
            >
              {loading ? <LoadingCompoent /> : "Candidatar-se"}
            </button>
          ) : (
            <div className="pt-4">
              <div
                class="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-slate-100 rounded-full shadow-sm  text-black mx-auto transition-all  duration-500 hover:bg-slate-100 lg:mx-0"
                role="alert"
              >
                <div class="flex justify-center">
                  <div className="justify-center">
                    <p class="font-bold pl-1 justify-center text-center">
                      Voce é candidato a essa vaga.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DialogVagas;
