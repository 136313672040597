import NavIncio from "../../Components/Header/NavInico";

export default function CadastroSucessoCandidato() {
  return (
    <>
      <div>
        <NavIncio />
      </div>
      <div className="w-screen h-screen  items-center justify-center">
        <div className="bg-white flex min-h-full flex-1 flex-col justify-center">
          <div className="text-center">
            <h1 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
              Cadastro realizado com sucesso!
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Agora você irá encontrar diversas oportunidades de emprego de uma
              forma simples, rápida e fácil. Basta clicar em Ir para login e
              realizar seu login.
            </p>
            <div className="mt-5 flex items-center justify-center gap-x-6">
              <a
                href="/login"
                className="rounded-md bg-blue-me px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-me focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                ir para Login <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
