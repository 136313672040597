import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Estado from "../../Service/Estados";
import Cidade from "../../Service/Cidade";
import { useForm } from "react-hook-form";
import ErrorsForm from "../../Components/FormError/Errors";
import InputMask from "react-input-mask";
import { reduxForm } from "redux-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlertComponent from "../../Components/Alert/Alert";
import NavIncio from "../../Components/Header/NavInico";

const BASE_URL = process.env.REACT_APP_URL_USUARIO;
const navigation = [{ name: "Quem somos?", href: "/quemsomos" }];

const escolaridade = [
  { id: "FI", nameescolaridade: "Ensino Fundamental - Incompleto" },
  { id: "FC", nameescolaridade: "Ensino Fundamental - Completo" },
  { id: "MI", nameescolaridade: "Ensino Medio - Incompleto" },
  { id: "MC", nameescolaridade: "Ensino Medio - Completo" },
  { id: "SI", nameescolaridade: "Ensino Superior - Incompleto" },
  { id: "SC", nameescolaridade: "Ensino Superior - Completo" },
];

const sexo = [
  {
    id: "M",
    namesexo: "Masculino",
    unavailable: false,
  },

  { id: "F", namesexo: "Feminino", unavailable: false },
  { id: "N", namesexo: "Não informar", unavailable: false },
];

let CadastroCandidato = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [valueEstado, setEstado] = useState("select");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  function closeModal(value) {
    setIsOpen(false);
  }

  const cidade = Cidade.filter((cidade) => {
    if (cidade.Estado === valueEstado) {
      return cidade.Nome;
    } else return null;
  });

  function openerror() {
    setTimeout(() => {
      setIsOpen(true);
    }, 3000);
    setIsOpen(false);
  }

  const handleChange = (e) => {
    const estado = e.target.options[e.target.selectedIndex].id;
    setEstado(estado);
  };

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/cadastro`, data)
      .then((resp) => {
        setMessage(resp.data[0].message);
        navigate("/cadastrosucessocandidato");
      })
      .catch((e) => {
        if (!e.response) {
          setMessage(
            "Ops! Algo de errado com nosso servidor, aguarde e tente novamente mais tarde."
          );
          setIsOpen(true);
          setType("error");
        } else {
          setMessage(e.response.data[0].message);
          setIsOpen(true);
          setType("error");
        }
      });
    setLoading(false);
  };

  return (
    <div className="bg-white">
      <NavIncio />
      <div className="relative isolate px-3 pt-2 lg:px-8">
        <div className="pt-16 flex flex-col">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl">
              Cadastro do candidato
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Cadastre-se e veja todas as nossas vagas.
            </p>
          </div>

          <form
            className="mx-auto mt-16 max-w-xl sm:mt-20"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Nome completo
                </label>
                <div className="mt-2.5">
                  <input
                    id="name_user"
                    name="nome"
                    {...register("nome", { minLength: 2, required: true })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.nome?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.nome?.type === "minLength" && (
                    <ErrorsForm
                      text={"Nome precisa ter pelo menos 2 letras."}
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Sexo
                </label>
                <div className="mt-2.5">
                  <select
                    id="sexo_id"
                    name="sexo"
                    {...register("sexo", { required: true })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    {sexo.map((sexo) => (
                      <option key={sexo.id} id={sexo.id} value={sexo.id}>
                        {sexo.namesexo}
                      </option>
                    ))}
                  </select>
                  {errors?.sexo?.type === "required" && (
                    <ErrorsForm text={"Faltou selecionar aqui."} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Data nascimento
                </label>
                <div className="mt-2.5">
                  <input
                    id="data_nascimento"
                    nome="datanascimento"
                    type="date"
                    {...register("datanascimento", {
                      required: true,
                      validate: (value) => {
                        const valueX = new Date(value).getFullYear();
                        if (valueX > new Date().getFullYear()) {
                          return valueX > new Date().getFullYear();
                        }
                        const date13 = new Date(Date.now()).getFullYear() - 13;
                        return valueX <= date13;
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.datanascimento?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.datanascimento?.type === "validate" && (
                    <ErrorsForm
                      text={"Ops! voce precisa ter pelo menos 14 anos."}
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    id="email_user"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.email?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}

                  {errors?.email?.type === "pattern" && (
                    <ErrorsForm text={"preencha o email corretamente"} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Telefone
                </label>
                <div className="mt-2.5">
                  <InputMask
                    id="telefone_user"
                    mask="(99)99999-9999"
                    type="tel"
                    {...register("telefone", {
                      minLength: 14,
                      required: true,
                      validate: (value) => {
                        const dd = value.slice(1, 3);
                        const numberpt1 = value.slice(4, 9);
                        const numberpt2 = value.slice(10, 14);
                        const numb = parseInt(`${dd}${numberpt1}${numberpt2}`);
                        const x = numb.toString().length;
                        return x >= 11;
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.telefone?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.telefone?.type === "minLength" && (
                    <ErrorsForm
                      text={"Não esqueça de adicionar o dd + seu numero"}
                    />
                  )}
                  {errors?.telefone?.type === "validate" && (
                    <ErrorsForm
                      text={
                        "Numbero incompleto nao esqueça do DD e do digito 9"
                      }
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Escolaridade
                </label>
                <div className="mt-2.5">
                  <select
                    id="escolaridade"
                    {...register("escolaridade", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="select">Selecione sua escolaridade</option>
                    {escolaridade.map((escolaridade) => (
                      <>
                        <option
                          key={escolaridade.id}
                          value={escolaridade.id}
                          id={escolaridade.id}
                        >
                          {escolaridade.nameescolaridade}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.escolaridade?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.escolaridade?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione uma escolaridade"} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Estado
                </label>
                <div className="mt-2.5">
                  <select
                    id="estado_user"
                    {...register("estado", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    onChange={handleChange}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="select">Selecione seu estado</option>
                    {Estado.map((Estado) => (
                      <>
                        <option
                          key={Estado.id}
                          id={Estado.ID}
                          value={Estado.ID}
                        >
                          {Estado.Nome} - {Estado.Sigla}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.estado?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.estado?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione um estado"} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Cidade
                </label>
                <div className="mt-2.5">
                  <select
                    id="cidade_user"
                    {...register("cidade", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option key={"select"} value="select">
                      Selecione sua cidade
                    </option>
                    {cidade.map((cidade) => (
                      <>
                        <option
                          key={cidade.id}
                          value={cidade.ID}
                          id={cidade.ID}
                        >
                          {cidade.Nome}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.cidade?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.cidade?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione uma cidade"} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Senha
                </label>
                <div className="mt-2.5">
                  <input
                    type="password"
                    id="senha_user"
                    {...register("senha", { minLength: 8, required: true })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.senha?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.senha?.type === "minLength" && (
                    <ErrorsForm
                      text={"Ops! Sua senha tem que ter no minimo 8 caracter."}
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Confimar senha
                </label>
                <div className="mt-2.5">
                  <input
                    type="password"
                    autoComplete="password"
                    {...register("confsenha", {
                      minLength: 8,
                      required: true,
                    })}
                    id="confsenha_user"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.confsenha?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.confsenha?.type === "minLength" && (
                    <ErrorsForm
                      text={"Ops! Sua senha tem que ter no minimo 8 caracter."}
                    />
                  )}
                  {watch("confsenha") !== watch("senha") &&
                  getValues("confsenha") ? (
                    <ErrorsForm
                      text={"Ops! Sua senha tem  que ser igual a de cima."}
                    />
                  ) : null}
                </div>
              </div>

              <label
                className="text-sm leading-6 text-gray-600"
                id="switch-1-label"
              >
                Ao clicar em "cadastre-se" voce concorda com{" "}
                <a
                  href="/termosecondicoes"
                  className="font-semibold text-blue-me"
                >
                  termos e condições.
                </a>
              </label>
            </div>
            <div className="mt-10 mb-10">
              {loading ? (
                <button
                  disabled
                  type="button"
                  class="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-4 h-4 mr-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Aguarde...
                </button>
              ) : (
                <button
                  type="submit"
                  className="block w-full rounded-md bg-blue-me  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-me focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Cadastre-se
                </button>
              )}
              <div className="mt-3">
                {message ? (
                  <AlertComponent
                    title={"Algo deu errado"}
                    message={message}
                    openDialog={isOpen}
                    closeModal={closeModal}
                    type={type}
                    className="mx-auto mt-10 max-w-xl"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CadastroCandidato;
