import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { useForm } from "react-hook-form";
import ErrorsForm from "../../../Components/FormError/Errors";
import InputMask from "react-input-mask";
import { reduxForm } from "redux-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { OrbitIcon } from "lucide-react";
import {
  deleteCurso,
  deleteExp,
  postCurso,
  postExp,
  putCurso,
} from "../../../actions/userActions";
import authSearchUser from "../../../Service/authSearchUser";

const BASE_URL = process.env.REACT_APP_URL_USUARIO;

//const [loading, setLoading] = useState(false);

async function submitDelete(id, close, handleLogout, navigate, valid) {
  //setLoading(true);
  const token = await authSearchUser(handleLogout, navigate, valid);

  if (token === undefined || !token) {
    return;
  }
  await deleteCurso(id, token);
  await close();
  //setLoading(false);
}

let DeletarEducacao = (props) => {
  return (
    <div className="relative isolate px-3  lg:px-8">
      <p>
        Voce realmente quer deletar <b>{props.dateDelete.nomecurso}</b> de suas
        Educações e certificações?{" "}
      </p>
      <div className="pt-5 grid grid-cols-2 gap-4">
        <button
          onClick={() => {
            submitDelete(
              props.dateDelete._id,
              props.close,
              props.handleLogout,
              props.navigate,
              props.valid
            );
          }}
          className="py-2.5 px-5 h-9 text-center font-semibold  bg-red-600 rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-red-600 lg:mx-0"
        >
          Sim,deletar
        </button>
        <button
          onClick={() => {
            props.close();
          }}
          className="py-2.5 px-5 h-9 text-center font-semibold bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
        >
          Não
        </button>
      </div>
    </div>
  );
};

export default DeletarEducacao;
