/* eslint-disable */
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import NavComp from "../../Components/Header/NavComp";
import {
  getComp,
  listvagacomp,
  preenchevaga,
  validateToken,
} from "../../actions/companyActions";
import { Context } from "../../context/auth";
import DialogComponent from "../../Components/Dialog/Dialog";
import EditarInfoEmpresa from "./Editar/EditarInfoComp";
import AdicionarVagas from "./Adicionar/Vagas";
import EditarVaga from "./Editar/Vagas";

import Cidade from "../../Service/Cidade";
import Estado from "../../Service/Estados";
import CandidatosVagas from "./Candidatos/candidatoVaga";
import AlertComponent from "../../Components/Alert/Alert";
import LoadingCompoent from "../../Components/Loading/Loading";

import {
  Settings,
  ClipboardList,
  BellRing,
  LogOut,
  User,
  SquareGantt,
  MoveRight,
  Database,
  Lock,
  Check,
  ListChecks,
  LayoutList,
  Pencil,
  ArrowRight,
} from "lucide-react";
import authSearchComp from "../../Service/authSearchComp";

export default function Dashboard2() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [vaga, setVaga] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAddVaga, setIsOpenAddVaga] = useState(false);
  const [isOpenEditarVaga, setIsOpenEditarVaga] = useState(false);
  const [isOpenCandidatoVaga, setIsOpenCandidatoVaga] = useState(false);
  const [dateVaga, setDateVaga] = useState("");
  const [listVagaCandidate, setCandaditeVaga] = useState([]);
  const [pages, setPages] = useState(1);
  const [RecallComponent, setReacall] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const [listFilter, setListFilter] = useState([]);
  const [token, setToken] = useState();
  const [comp, setComp] = useState("");
  const [vagasaprovadas, setVagasAprovadas] = useState("");
  const [vagasCadMenu, setVagasCadMenu] = useState(0);
  const [vagasAprovMenu, setVagasAprovMenu] = useState(0);
  const [vagasPendMenu, setVagasPendMenu] = useState(0);

  const { handleLogout } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    async function compDate() {
      setLoading(true);
      const token = await authSearchComp(handleLogout, navigate, validateToken);
      if (token === undefined || !token) {
        return;
      }

      setToken(token);

      const getCompDate = await getComp(token);
      const getVagasAprovadas = await listvagacomp(token);

      await setComp(getCompDate);
      await setVagasAprovadas(getVagasAprovadas);
      await setVagasCadMenu(getVagasAprovadas.length);
      await setVagasAprovMenu(
        getVagasAprovadas.filter((el) => el.aprovada === 2)
      );
      await setVagasPendMenu(
        getVagasAprovadas.filter((el) => el.aprovada === 1)
      );

      setLoading(false);
    }
    compDate();
  }, []);

  useEffect(() => {
    async function compDate() {
      setLoading(true);
      const token = await authSearchComp(handleLogout, navigate, validateToken);
      if (token === undefined || !token) {
        return;
      }

      setToken(token);
      const getCompDate = await getComp(token);
      const getVagasAprovadas = await listvagacomp(token);

      await setComp(getCompDate);
      await setVagasAprovadas(getVagasAprovadas);
      setLoading(false);
    }
    compDate();
  }, [RecallComponent]);

  function openInformacoes() {
    setIsOpen(true);
  }

  function openAdicionarVagas() {
    setIsOpenAddVaga(true);
  }

  async function marcarVagaPreenchida(idVaga, p) {
    const resp = await preenchevaga(idVaga, p, token);
    setReacall(Math.random());
  }

  function openEditarVagas(id) {
    const result = vagasaprovadas.filter((vagas) => vagas._id === id);
    setVaga(result);
    setIsOpenEditarVaga(true);
  }

  async function closeModal() {
    setReacall(Math.random());
    setIsOpen(false);
  }

  async function closeModalAddVaga() {
    setReacall(Math.random());
    setIsOpenAddVaga(false);
  }

  async function closeModalEditarVaga() {
    setReacall(Math.random());
    setIsOpenEditarVaga(false);
  }

  function openCandidatoVagas(id) {
    const result = vagasaprovadas.filter((vagas) => vagas._id === id);
    setVaga(result);
    setIsOpenCandidatoVaga(true);
  }

  async function closeModalCandidatoVagas() {
    setReacall(Math.random());
    setIsOpenCandidatoVaga(false);
  }

  console.log(vagasAprovMenu);

  return (
    <>
      <DialogComponent
        openDialog={isOpenAddVaga}
        closeModal={closeModalAddVaga}
        title={"Adicionando vagas"}
        component={
          <AdicionarVagas
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
          />
        }
      />

      <DialogComponent
        openDialog={isOpenEditarVaga}
        closeModal={closeModalEditarVaga}
        title={"Editando vaga"}
        component={
          <EditarVaga
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            vagaDate={vaga}
          />
        }
      />

      <DialogComponent
        openDialog={isOpenCandidatoVaga}
        closeModal={closeModalCandidatoVagas}
        title={"Lista de Candidatos"}
        component={
          <CandidatosVagas
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            vagaDate={vaga}
          />
        }
      />

      <div className="h-screen flex flex-col">
        <div className="flex flex-1">
          <NavComp compdate={comp} />
          <main className="flex-1 bg-gray-400/10 p-4">
            <AlertComponent
              message={
                "Caso precise de ajuda e/ou suporte, entre em contato conosco atraves do nosso email suporte@meemprega.com.br"
              }
            />
            <p className="text-justify text-2xl font-semibold">
              Olá, {comp.nome}
            </p>
            <p>Estamos prontos para ajudar você a alcançar seus objetivos.</p>
            <div className="grid grid-cols-1 gap-4 mt-10">
              <div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="bg-white p-4 rounded-2xl">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center 5bg-blue-300">
                        <SquareGantt className="h-12 w-12 text-blue-me" />
                      </div>
                      <div>
                        <p className="text-4xl font-semibold text-blue-me text-right">
                          {vagasCadMenu}
                        </p>
                        <p className="text-sm font-light text-right">
                          Total de vagas cadastradas
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white p-4 rounded-2xl">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center 5bg-blue-300">
                        <ListChecks className="h-12 w-12 text-green-700" />
                      </div>
                      <div>
                        <p className="text-4xl font-semibold text-green-700 text-right">
                          {vagasAprovMenu.length}
                        </p>
                        <p className="text-sm font-light text-right">
                          Vagas Aprovadas
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white p-4 rounded-2xl">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center 5bg-blue-300">
                        <LayoutList className="h-12 w-12 text-yellow-400" />
                      </div>
                      <div>
                        <p className="text-4xl font-semibold text-yellow-400 text-right">
                          {vagasPendMenu.length}
                        </p>
                        <p className="text-sm font-light text-right">
                          Vagas Pendentes
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-col-1 gap-4">
                <div>
                  <div className="grid grid-cols-1 gap-4">
                    <div className="bg-white rounded-xl p-4 col-span-2">
                      <div className="flex gap-4 items-center">
                        <Database className="h-5 w-5" />
                        <p className="text-lg">Suas Vagas</p>
                        <div className="flex flex-1 justify-end">
                          <div className="justify-end content-end items-end flex ">
                            <button
                              onClick={openAdicionarVagas}
                              className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                            >
                              Adicionar novas vagas
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 p-6">
                        {loading ? (
                          <LoadingCompoent />
                        ) : vagasaprovadas.length === 0 ? (
                          ""
                        ) : (
                          vagasaprovadas.map((vagas) => (
                            <>
                              <div className="flex gap-8">
                                <div className="flex flex-col">
                                  <p className="text-md font-semibold">
                                    {vagas.titulovaga}
                                  </p>
                                  <small className="text-sm">
                                    {vagas.aprovada === 1
                                      ? "Pendente de aprovação"
                                      : vagas.aprovada === 2
                                      ? "Aprovada"
                                      : "Reprovada"}
                                  </small>
                                </div>
                                {vagas.aprovada === 1 ? (
                                  <div className="flex flex-1 gap-4 justify-end">
                                    <button className="py-2.5 px-5 h-9 text-center font-semibold block bg-yellow-600 rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-yellow-600 lg:mx-0">
                                      Pendente de aprovação
                                    </button>
                                  </div>
                                ) : vagas.aprovada === 3 ? (
                                  <div className="flex flex-1 gap-4 justify-end">
                                    <button className="flex gap-4 font-medium text-red-500">
                                      Reprovado
                                    </button>
                                  </div>
                                ) : (
                                  <div className="flex flex-1 gap-4 justify-end">
                                    <button
                                      onClick={(e) =>
                                        openEditarVagas(vagas._id)
                                      }
                                      className="py-2.5 px-5 h-9 text-center font-semibold block bg-red-600 rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-red-600 lg:mx-0"
                                    >
                                      Editar
                                    </button>

                                    <button
                                      className="py-2.5 px-5 h-9 text-center font-semibold block bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                                      onClick={(e) =>
                                        openCandidatoVagas(vagas._id)
                                      }
                                    >
                                      Ver Candidatos
                                    </button>

                                    <button
                                      onClick={() =>
                                        marcarVagaPreenchida(
                                          vagas._id,
                                          !vagas.preenchida
                                        )
                                      }
                                      className="py-2.5 px-5 h-9 text-center font-semibold block bg-green-600 rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-green-600 lg:mx-0"
                                    >
                                      {vagas.preenchida
                                        ? "Desfazer como preenchida"
                                        : "Marcar como prenchida"}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
