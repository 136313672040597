/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import { Calendar, Hammer, MapPin, Store } from "lucide-react";
import NavCandidato from "../../Components/Header/NavCandidato";
import { getlistVagas } from "../../actions/vagasAction";
import Estado from "../../Service/Estados";
import Cidade from "../../Service/Cidade";
import DialogVagas from "./Vagas/DialogVagas";
import { getCandidateFeed, validateToken } from "../../actions/userActions";
import SearchComponent from "../../Components/Search/Search";
import PaginationComponent from "../../Components/Pagination/Pagination";
import LoadingCompoent from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/auth";
import authSearchUser from "../../Service/authSearchUser";
import ModalVagas from "./Vagas/ModalVaga";

const navigation = [{ name: "Quem somos?", href: "#" }];

export default function Vagas() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [vagas, setVagas] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dateVaga, setDateVaga] = useState("");
  const [listVagaCandidate, setCandaditeVaga] = useState([]);
  const [pages, setPages] = useState(1);
  const [RecallComponent, setReacall] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const [listFilter, setListFilter] = useState([]);

  const { handleLogout } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    async function uploadImgP() {
      const token = await authSearchUser(handleLogout, navigate, validateToken);
      const vagas = await getlistVagas(pages);
      const candidate = await getCandidateFeed(token);
      await setVagas(vagas);
      await setCandaditeVaga(candidate);
    }
    uploadImgP();
  }, []);

  useEffect(() => {
    async function uploadImgP() {
      setLoading(true);
      const token = await authSearchUser(handleLogout, navigate, validateToken);
      const vagas = await getlistVagas(pages);
      const candidate = await getCandidateFeed(token);
      await setVagas(vagas);
      await setCandaditeVaga(candidate);
      setLoading(false);
    }
    uploadImgP();
  }, [pages]);

  useEffect(() => {
    async function uploadImgP() {
      setLoading(true);
      const token = await authSearchUser(handleLogout, navigate, validateToken);
      const vagas = await getlistVagas(pages);
      const candidate = await getCandidateFeed(token);
      await setVagas(vagas);
      await setCandaditeVaga(candidate);
      setLoading(false);
    }
    uploadImgP();
  }, [RecallComponent]);

  function dateFormat(date) {
    const newDate = new Date(date);
    const dateformat = newDate.toLocaleDateString("pt-br");
    return dateformat;
  }

  async function closeModal() {
    setIsOpen(false);
    setReacall(Math.random());
  }

  function openVagas(date, id) {
    setDateVaga(date);
    setIsOpen(true);
  }

  function CandidateVagas(id) {
    if (listVagaCandidate[0] === undefined) {
      return false;
    } else {
      for (var i = 0; i <= listVagaCandidate.length; i++) {
        if (listVagaCandidate[i] === undefined) {
          return;
        } else {
          if (listVagaCandidate[i].idVaga === id) {
            return true;
          }
        }
      }
    }
  }

  function searchVaga(e) {
    const List = vagas.docs;
    const listfilter = List.filter((search) =>
      search.titulovaga.toLowerCase().includes(e.toLowerCase())
    );
    setListFilter(listfilter);
  }

  function pageListVaga(page) {
    setPages(page);
  }

  return (
    <>
      <div className="h-screen flex flex-col">
        <div className="flex flex-1">
          <NavCandidato />

          <main className="flex-1 bg-gray-400/10 p-4">
            <SearchComponent search={searchVaga} />
            {loading ? (
              <div className="justify-center items-center flex flex-row">
                <LoadingCompoent />
              </div>
            ) : (
              ""
            )}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
              <ModalVagas open={isOpen} onClose={() => setIsOpen(false)}>
                <div className="text-left sm:w-96">
                  <DialogVagas dateVaga={dateVaga} />
                </div>
              </ModalVagas>

              {listFilter.length > 0
                ? listFilter.map((vagas) => (
                    <div className="bg-white p-6 flex flex-col rounded-lg">
                      <div className="flex flew-row gap-4">
                        <p className="flex text-center items-center font-semibold text-xl">
                          {vagas.titulovaga}
                        </p>
                      </div>

                      <div className="flex flex-col sm:flex-row gap-4 pt-4">
                        <div className="flex flex-row gap-2 rounded-full">
                          <Store />
                          <p>{vagas.Company.nome}</p>
                        </div>
                        <div className="flex flex-row gap-2">
                          <MapPin />
                          {Cidade.map((cidadeValue) => {
                            if (cidadeValue.ID == vagas.cidade) {
                              return cidadeValue.Nome;
                            } else {
                              return "";
                            }
                          })}{" "}
                          -{" "}
                          {Estado.map((estadoValue) => {
                            if (estadoValue.ID == vagas.estado) {
                              return estadoValue.Sigla;
                            } else {
                              return "";
                            }
                          })}
                        </div>
                        <div className="flex flex-row gap-2">
                          <Calendar />
                          <p>Data da vaga: {dateFormat(vagas.datavaga)}</p>
                        </div>
                        <div className="flex flex-row gap-2">
                          <Hammer />
                          <p className="font-semibold">
                            {vagas.experiencia === 1
                              ? "Necessario Experiencia"
                              : "Sem necessidade de experiencia"}
                          </p>
                        </div>
                      </div>

                      <div className="flex pt-4 justify-center sm:justify-end">
                        <button
                          onClick={() => {
                            openVagas(vagas, vagas._id);
                          }}
                          className="py-2.5 px-5 h-9 text-center font-semibold block w-52 bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                        >
                          Ver mais detalhes da vaga
                        </button>
                      </div>
                    </div>
                  ))
                : vagas.docs === undefined
                ? ""
                : vagas.docs.map((vagas, index) => (
                    <div className="bg-white p-6 flex flex-col rounded-lg">
                      <div className="flex flew-row gap-4">
                        <p className="flex text-center items-center font-semibold text-xl">
                          {vagas.titulovaga}
                        </p>
                      </div>

                      <div className="flex flex-col sm:flex-row gap-4 pt-4">
                        <div className="flex flex-row gap-2 rounded-full">
                          <Store />
                          <p>{vagas.Company.nome}</p>
                        </div>
                        <div className="flex flex-row gap-2">
                          <MapPin />
                          {Cidade.map((cidadeValue) => {
                            if (cidadeValue.ID == vagas.cidade) {
                              return cidadeValue.Nome;
                            } else {
                              return "";
                            }
                          })}{" "}
                          -{" "}
                          {Estado.map((estadoValue) => {
                            if (estadoValue.ID == vagas.estado) {
                              return estadoValue.Sigla;
                            } else {
                              return "";
                            }
                          })}
                        </div>
                        <div className="flex flex-row gap-2">
                          <Calendar />
                          <p>Data da vaga: {dateFormat(vagas.datavaga)}</p>
                        </div>
                        <div className="flex flex-row gap-2">
                          <Hammer />
                          <p className="font-semibold">
                            {vagas.experiencia === 1
                              ? "Necessario Experiencia"
                              : "Sem necessidade de experiencia"}
                          </p>
                        </div>
                      </div>

                      <div className="flex pt-4 justify-center sm:justify-end">
                        <button
                          onClick={() => {
                            openVagas(vagas, vagas._id);
                          }}
                          className="py-2.5 px-5 h-9 text-center font-semibold block w-52 bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                        >
                          Ver mais detalhes da vaga
                        </button>
                      </div>
                    </div>
                  ))}
            </div>
            <div className="p-2 pb-16  items-center text-center content-center justify-center">
              <PaginationComponent
                page={vagas.pages}
                pageSelect={vagas.page}
                pageListVaga={pageListVaga}
              />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
