import { useState } from "react";

import { useForm } from "react-hook-form";

import { Objetivo_Competencia } from "../../../actions/userActions";
import authSearchUser from "../../../Service/authSearchUser";
import LoadingCompoent from "../../../Components/Loading/Loading";
import AlertComponent from "../../../Components/Alert/Alert";

let AdicionarHabilidade = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [statusMessage, setStatusMessage] = useState();

  const [isOpen, setIsOpen] = useState(false);

  function openMessage(status) {
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
    setIsOpen(true);
  }

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const token = await authSearchUser(
      props.handleLogout,
      props.navigate,
      props.valid
    );

    if (token === undefined || !token) {
      return;
    }
    const resp = Objetivo_Competencia(token, data);
    openMessage();
    setStatusMessage(true);
    setMessage(resp);
    setLoading(false);
  };

  return (
    <div className="relative isolate px-3  lg:px-8">
      <div className="flex flex-col">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="mx-auto max-w-xl mt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label
              for="message"
              className="block mb-2 text-sm font-medium text-black"
            >
              Descreva suas Habilidade, Objetivos e competencias
            </label>
            <textarea
              id="message"
              rows="4"
              name="message_id"
              {...register("objetivo", {
                required: true,
              })}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Descreva suas Habilidade, Objetivos e competencias"
            ></textarea>

            <div className="mt-10 mb-10">
              {isOpen ? (
                <AlertComponent message={message} status={statusMessage} />
              ) : (
                <button
                  type="submit"
                  className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                >
                  {loading ? <LoadingCompoent /> : "Adicionar"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdicionarHabilidade;
