import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function PaginationComponent(props) {
  const page = props.page || 1;
  const pageSelect = props.pageSelect;

  const listPage = [];

  for (var i = 1; i <= page; i++) {
    if (pageSelect === i) {
      listPage.push(
        <li>
          <button
            onClick={(e) => props.pageListVaga(e.target.innerHTML)}
            aria-current="page"
            className="flex items-center  justify-center px-4 h-10 leading-tight text-white bg-blue-me border border-gray-300"
          >
            {[i]}
          </button>
        </li>
      );
    } else {
      listPage.push(
        <li>
          <button
            onClick={(e) => props.pageListVaga(e.target.innerHTML)}
            aria-current="page"
            className="flex items-center  justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
          >
            {[i]}
          </button>
        </li>
      );
    }
  }

  return (
    <nav
      className="justify-center items-center content-center text-center"
      aria-label="Page navigation example"
    >
      <ul class="flex items-center justify-center  -space-x-px h-10 text-base">
        {listPage}
      </ul>
    </nav>
  );
}
