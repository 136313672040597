/* eslint-disable */
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import NavComp from "../../Components/Header/NavComp";
import {
  getComp,
  listvagacomp,
  validateToken,
} from "../../actions/companyActions";
import { Context } from "../../context/auth";
import DialogComponent from "../../Components/Dialog/Dialog";
import EditarInfoEmpresa from "./Editar/EditarInfoComp";
import AdicionarVagas from "./Adicionar/Vagas";
import EditarVaga from "./Editar/Vagas";
import { useForm } from "react-hook-form";

import Cidade from "../../Service/Cidade";
import Estado from "../../Service/Estados";
import AlertComponent from "../../Components/Alert/Alert";
import authSearchComp from "../../Service/authSearchComp";

export default function ConfiguracoesComp() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [vaga, setVaga] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAddVaga, setIsOpenAddVaga] = useState(false);
  const [isOpenEditarVaga, setIsOpenEditarVaga] = useState(false);
  const [dateVaga, setDateVaga] = useState("");
  const [listVagaCandidate, setCandaditeVaga] = useState([]);
  const [pages, setPages] = useState(1);
  const [RecallComponent, setReacall] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const [listFilter, setListFilter] = useState([]);
  const [token, setToken] = useState();
  const [comp, setComp] = useState("");
  const [vagasaprovadas, setVagasAprovadas] = useState("");

  const { handleLogout } = useContext(Context);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    async function compDate() {
      const token = await authSearchComp(handleLogout, navigate, validateToken);
      if (token === undefined || !token) {
        return;
      }

      setToken(token);

      const getCompDate = await getComp(token);
      const getVagasAprovadas = await listvagacomp(token);

      await setComp(getCompDate);
      await setVagasAprovadas(getVagasAprovadas);
    }
    compDate();
  }, []);

  useEffect(() => {
    async function compDate() {
      const token = await authSearchComp(handleLogout, navigate, validateToken);
      if (token === undefined || !token) {
        return;
      }

      setToken(token);

      const getCompDate = await getComp(token);
      const getVagasAprovadas = await listvagacomp(token);

      await setComp(getCompDate);
      await setVagasAprovadas(getVagasAprovadas);
    }
    compDate();
  }, [RecallComponent]);

  function openInformacoes() {
    setIsOpen(true);
  }

  function openAdicionarVagas() {
    setIsOpenAddVaga(true);
  }

  function openEditarVagas(id) {
    const result = vagasaprovadas.filter((vagas) => vagas._id === id);
    setVaga(result);
    setIsOpenEditarVaga(true);
  }

  async function closeModal() {
    setReacall(Math.random());
    setIsOpen(false);
  }

  async function closeModalAddVaga() {
    setReacall(Math.random());
    setIsOpenAddVaga(false);
  }

  async function closeModalEditarVaga() {
    setReacall(Math.random());
    setIsOpenEditarVaga(false);
  }

  return (
    <>
      <DialogComponent
        openDialog={isOpen}
        closeModal={closeModal}
        title={"Editar informações da empresa"}
        component={<EditarInfoEmpresa compDate={comp} />}
      />

      <div className="h-screen flex flex-col">
        <div className="flex flex-1">
          <NavComp />
          <main className="flex-1 bg-gray-400/10 p-4">
            <AlertComponent
              message={
                "Caso precise de ajuda e/ou suporte, entre em contato conosco atraves do nosso email suporte@meemprega.com.br"
              }
            />
            <p className="text-justify text-2xl font-semibold">
              Olá, {comp.nome}
            </p>
            <p>
              Cuidado, questão muito importante referente a alteração de senha.
            </p>
            <div className="grid grid-cols-1 gap-4 mt-10">
              <div className="p-2">
                <div className="p-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-blue-400">
                  <span class="font-medium">Sobre alteração de senha: </span>
                  <p className="text-justify gap-4 pb-5">
                    Para segurança da sua empresa a alteração de senha sera
                    atravez do "esqueceu sua senha" caso queira altera a senha
                    da sua empresa click no link abaixo.
                  </p>
                  <a
                    href="/esqueceusenha"
                    className="py-1.5 px-5 h-8 text-center font-semibold block w-full bg-yellow-600 rounded-full shadow-sm text-sm text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                  >
                    Click aqui para alterar a senha
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
