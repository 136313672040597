/* eslint-disable */
import { useState, useContext } from "react";
import { Context } from "../../context/auth";
import { useNavigate } from "react-router-dom";

import { Settings, ClipboardList, BellRing, LogOut } from "lucide-react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Seu curriculo", href: "#" },
  { name: "Vagas", href: "#" },
  { name: "Notificações", href: "#" },
  { name: "Sair", href: "#" },
];

export default function NavAdmin() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { handleLogout } = useContext(Context);

  const navigate = useNavigate();

  function LogOutComp() {
    const user = 2;
    handleLogout(user);
    navigate("/");
  }

  return (
    <>
      <div className="p-6  hidden lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <aside className="w-72 bg-white  hidden lg:block">
        <div className="">
          <p className="text-2xl text-center mt-16 font-semibold leading-6 text-gray-900">
            BlueJob
          </p>

          <nav className="space-y-4 p-3 justify-center content-center mt-10">
            <a
              type="button"
              onClick={() => LogOutComp()}
              className="flex items-center gap-6  hover:text-blue-me justify-start p-3 font-semibold text-zinc-150 text-lg rounded  w-64  h-10"
            >
              <LogOut className="h-6 w-6" />
              Sair
            </a>
          </nav>
        </div>
      </aside>

      <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600 lg:hidden">
        <div class="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
          <a
            type="button"
            onClick={() => LogOutComp()}
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
          >
            <LogOut className="h-6 w-6 group-hover:text-blue-600" />
            <span class="text-sm text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">
              Sair
            </span>
          </a>
        </div>
      </div>
    </>
  );
}
