import { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorsForm from "../../Components/FormError/Errors";
import { useNavigate } from "react-router-dom";
import NavIncio from "../../Components/Header/NavInico";
import forgotPasswordUser from "../../actions/userActions";
import forgotPasswordComp from "../../actions/companyActions";

let EsqueceuSenha = (props) => {
  const [loading, setLoading] = useState(false);

  const [userOrComp, setUserOrComp] = useState(1);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitUser = async (data) => {
    setLoading(true);
    await forgotPasswordUser(data);
    navigate("/recuperacaosenha");
    setLoading(false);
  };

  const onSubmitComp = async (data) => {
    setLoading(true);
    await forgotPasswordComp(data);
    navigate("/recuperacaosenha");
    setLoading(false);
  };

  return (
    <div className="bg-white">
      <NavIncio />
      <div className="relative isolate px-3 pt-2 lg:px-8">
        <div className="pt-16 flex flex-col">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl">
              Esqueceu sua senha?
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Não se preocupe, digite seu e-mail e vamos recupera-lá.
            </p>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className="mx-auto mt-16 max-w-xl sm:mt-20"
              onSubmit={handleSubmit(
                userOrComp === 1 ? onSubmitUser : onSubmitComp
              )}
            >
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    id="email_user"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.email?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}

                  {errors?.email?.type === "pattern" && (
                    <ErrorsForm text={"preencha o email corretamente"} />
                  )}
                </div>

                <div className="mt-10">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Quem é voce?
                  </label>
                  <div class="flex items-center ps-4 border mt-3 border-gray-200 rounded dark:border-gray-700">
                    <input
                      id="bordered-radio-1"
                      type="radio"
                      checked
                      value=""
                      onClick={(e) => setUserOrComp(1)}
                      name="bordered-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-radio-1"
                      className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Candidato
                    </label>
                  </div>
                  <div class="flex items-center ps-4 border mt-3 border-gray-200 rounded dark:border-gray-700">
                    <input
                      id="bordered-radio-2"
                      type="radio"
                      value=""
                      onClick={(e) => setUserOrComp(2)}
                      name="bordered-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-radio-2"
                      className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Empresa
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-10 mb-10">
                {loading ? (
                  <button
                    disabled
                    type="button"
                    class="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Aguarde...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                  >
                    Recupera minha senha
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsqueceuSenha;
