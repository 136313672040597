import NavIncio from "../../Components/Header/NavInico";

export default function SenhaAlteradaSucesso() {
  return (
    <>
      <div>
        <NavIncio />
      </div>
      <div className="w-screen h-screen  items-center justify-center">
        <div className="bg-white flex min-h-full flex-1 flex-col justify-center">
          <div className="text-center">
            <h1 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
              Senha Alterada com sucesso!
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Sua senha foi alterada com sucesso, va para o login e acesse sua
              conta novamente com a nova senha.
            </p>
            <div className="mt-5 flex items-center justify-center gap-x-6">
              <a
                href="/login"
                className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
              >
                ir para Login <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
