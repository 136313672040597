import { useState } from "react";
import axios from "axios";

const BASE_URL_USER = process.env.REACT_APP_URL_USUARIO;
const BASE_URL_COMP = process.env.REACT_APP_URL_COMPANY;
const BASE_URL_ADMIN = process.env.REACT_APP_URL_ADMIN;

export default function useAuth(props) {
  const [authenticated, setAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);

  async function handleLogin(value, user) {
    if (user === 1) {
      const response = await axios.post(`${BASE_URL_USER}/auth`, value);
      await localStorage.setItem("_myuser_", JSON.stringify(response.data));
      await setAuthenticated(true);
    } else if (user === 2) {
      const response = await axios.post(`${BASE_URL_COMP}/auth`, value);
      await localStorage.setItem("_mycomp_", JSON.stringify(response.data));
      await setAuthenticated(true);
    } else if (user === 3) {
      const response = await axios.post(`${BASE_URL_ADMIN}/auth`, value);
      await localStorage.setItem("_myadmin_", JSON.stringify(response.data));
      await setAuthenticated(true);
    } else {
      await setAuthenticated(false);
    }
  }

  function handleLogout(user) {
    if (user === 1) {
      setAuthenticated(false);
      localStorage.removeItem("_myuser_");
    } else if (user === 2) {
      setAuthenticated(false);
      localStorage.removeItem("_mycomp_");
    } else if (user === 3) {
      setAuthenticated(false);
      localStorage.removeItem("_myadmin_");
    } else {
      setAuthenticated(false);
    }
  }

  return { authenticated, loading, handleLogin, handleLogout };
}
