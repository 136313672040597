import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Estado from "../../../Service/Estados";
import Cidade from "../../../Service/Cidade";
import { useForm, Controller } from "react-hook-form";
import ErrorsForm from "../../../Components/FormError/Errors";
import InputMask from "react-input-mask";
import { reduxForm } from "redux-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlertComponent from "../../../Components/Alert/Alert";
import NavIncio from "../../../Components/Header/NavInico";
import { useEffect } from "react";
import { getUser, updateUser } from "../../../actions/userActions";
import LoadingCompoent from "../../../Components/Loading/Loading";
import authSearchUser from "../../../Service/authSearchUser";

const BASE_URL = process.env.REACT_APP_URL_USUARIO;
const navigation = [{ name: "Quem somos?", href: "/quemsomos" }];

const escolaridade = [
  { id: "FI", nameescolaridade: "Ensino Fundamental - Incompleto" },
  { id: "FC", nameescolaridade: "Ensino Fundamental - Completo" },
  { id: "MI", nameescolaridade: "Ensino Medio - Incompleto" },
  { id: "MC", nameescolaridade: "Ensino Medio - Completo" },
  { id: "SI", nameescolaridade: "Ensino Superior - Incompleto" },
  { id: "SC", nameescolaridade: "Ensino Superior - Completo" },
];

const sexo = [
  {
    id: "M",
    namesexo: "Masculino",
    unavailable: false,
  },

  { id: "F", namesexo: "Feminino", unavailable: false },
  { id: "N", namesexo: "Não informar", unavailable: false },
];

let EditarInfoPessoais = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [valueEstado, setEstado] = useState(
    String(props.userDate.estado) || "select"
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [type, setType] = useState();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  async function closeModal(value) {
    const isLocalStorage = JSON.parse(localStorage.getItem("_myuser_"));
    const tokenLocal = isLocalStorage.token;
    await getUser(tokenLocal);
  }

  const cidade = Cidade.filter((cidade) => {
    if (cidade.Estado === valueEstado) {
      return cidade.Nome;
    } else return null;
  });

  //Mask input
  const handlePhone = (event) => {
    let input = event.target;

    input.value = phoneMask(input.value);
  };
  const phoneMask = (value) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  };

  const handleChange = (e) => {
    const estado = e.target.options[e.target.selectedIndex].id;
    setEstado(estado);
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });

  function openMessage(status) {
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
    setIsOpen(true);
  }

  const onSubmit = async (data) => {
    setLoading(true);
    /*  const isLocalStorage = JSON.parse(localStorage.getItem("_myuser_"));
    const tokenLocal = isLocalStorage.token;
*/
    const token = await authSearchUser(
      props.handleLogout,
      props.navigate,
      props.valid
    );

    if (token === undefined || !token) {
      return;
    }

    const resp = await updateUser(data, token);
    openMessage();
    setStatusMessage(true);
    setMessage(resp.data[0].message);
    setLoading(false);
  };

  useEffect(() => {
    async function editForm() {
      await authSearchUser(props.handleLogout, props.navigate, props.valid);

      const {
        nome,
        email,
        telefone,
        datanascimento,
        sexo,
        escolaridade,
        cidade,
        estado,
      } = props.userDate;
      setEstado(String(estado));
      setValue("nome", nome);
      setValue("email", email);
      setValue("datanascimento", datanascimento);
      setValue("telefone", telefone);
      setValue("sexo", sexo);
      setValue("escolaridade", escolaridade);
      setValue("estado", estado);
      setValue("cidade", cidade);
    }
    editForm();
  }, []);

  return (
    <div className="bg-white">
      <div className="relative isolate px-3 pt-2 lg:px-8">
        <div className="pt-5 flex flex-col">
          <form
            className="mx-auto  max-w-xl "
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Nome completo
                </label>
                <div className="mt-2.5">
                  <input
                    id="name_user"
                    name="nome"
                    {...register("nome", { minLength: 2, required: true })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.nome?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.nome?.type === "minLength" && (
                    <ErrorsForm
                      text={"Nome precisa ter pelo menos 2 letras."}
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Sexo
                </label>
                <div className="mt-2.5">
                  <select
                    id="sexo_id"
                    name="sexo"
                    {...register("sexo", { required: true })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    {sexo.map((sexo) => (
                      <option key={sexo.id} id={sexo.id} value={sexo.id}>
                        {sexo.namesexo}
                      </option>
                    ))}
                  </select>
                  {errors?.sexo?.type === "required" && (
                    <ErrorsForm text={"Faltou selecionar aqui."} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Data nascimento
                </label>
                <div className="mt-2.5">
                  <input
                    id="data_nascimento"
                    nome="datanascimento"
                    type="date"
                    {...register("datanascimento", {
                      required: true,
                      validate: (value) => {
                        const valueX = new Date(value).getFullYear();
                        if (valueX > new Date().getFullYear()) {
                          return valueX > new Date().getFullYear();
                        }
                        const date13 = new Date(Date.now()).getFullYear() - 13;
                        return valueX <= date13;
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.datanascimento?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.datanascimento?.type === "validate" && (
                    <ErrorsForm
                      text={"Ops! voce precisa ter pelo menos 14 anos."}
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    id="email_user"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.email?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}

                  {errors?.email?.type === "pattern" && (
                    <ErrorsForm text={"preencha o email corretamente"} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Telefone
                </label>
                <div className="mt-2.5">
                  <input
                    onKeyUp={(e) => {
                      handlePhone(e);
                    }}
                    {...register("telefone", {
                      minLength: 14,
                      required: true,
                      validate: (value) => {
                        const dd = value.slice(1, 3);
                        const numberpt1 = value.slice(4, 9);
                        const numberpt2 = value.slice(10, 14);
                        const numb = parseInt(`${dd}${numberpt1}${numberpt2}`);
                        const x = numb.toString().length;
                        return x >= 11;
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />

                  {errors?.telefone?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.telefone?.type === "minLength" && (
                    <ErrorsForm
                      text={"Não esqueça de adicionar o dd + seu numero"}
                    />
                  )}
                  {errors?.telefone?.type === "validate" && (
                    <ErrorsForm
                      text={
                        "Numbero incompleto nao esqueça do DD e do digito 9"
                      }
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Escolaridade
                </label>
                <div className="mt-2.5">
                  <select
                    id="escolaridade"
                    {...register("escolaridade", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="select">Selecione sua escolaridade</option>
                    {escolaridade.map((escolaridade) => (
                      <>
                        <option
                          key={escolaridade.id}
                          value={escolaridade.id}
                          id={escolaridade.id}
                        >
                          {escolaridade.nameescolaridade}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.escolaridade?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.escolaridade?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione uma escolaridade"} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Estado
                </label>
                <div className="mt-2.5">
                  <select
                    id="estado_user"
                    {...register("estado", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    onChange={handleChange}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="select">Selecione seu estado</option>
                    {Estado.map((Estado) => (
                      <>
                        <option
                          key={Estado.id}
                          id={Estado.ID}
                          value={Estado.ID}
                        >
                          {Estado.Nome} - {Estado.Sigla}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.estado?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.estado?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione um estado"} />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Cidade
                </label>
                <div className="mt-2.5">
                  <select
                    id="cidade_user"
                    {...register("cidade", {
                      required: true,
                      validate: (value) => {
                        return value !== "select";
                      },
                    })}
                    className="w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option key={"select"} value="select">
                      Selecione sua cidade
                    </option>
                    {cidade.map((cidade) => (
                      <>
                        <option
                          key={cidade.id}
                          value={cidade.ID}
                          id={cidade.ID}
                        >
                          {cidade.Nome}
                        </option>
                      </>
                    ))}
                  </select>
                  {errors?.cidade?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.cidade?.type === "validate" && (
                    <ErrorsForm text={"Ops! Selecione uma escolaridade"} />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-10 mb-10">
              {isOpen ? (
                <AlertComponent message={message} status={statusMessage} />
              ) : (
                <button
                  type="submit"
                  className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                >
                  {loading ? <LoadingCompoent /> : "Editar"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditarInfoPessoais;
/*
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { userService, alertService } from "@/_services";

function AddEdit({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    role: Yup.string().required("Role is required"),
    password: Yup.string()
      .transform((x) => (x === "" ? undefined : x))
      .concat(isAddMode ? Yup.string().required("Password is required") : null)
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .transform((x) => (x === "" ? undefined : x))
      .when("password", (password, schema) => {
        if (password || isAddMode)
          return schema.required("Confirm Password is required");
      })
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data) {
    return isAddMode ? createUser(data) : updateUser(id, data);
  }

  function createUser(data) {
    return userService
      .create(data)
      .then(() => {
        alertService.success("User added", { keepAfterRouteChange: true });
        history.push(".");
      })
      .catch(alertService.error);
  }

  function updateUser(id, data) {
    return userService
      .update(id, data)
      .then(() => {
        alertService.success("User updated", { keepAfterRouteChange: true });
        history.push("..");
      })
      .catch(alertService.error);
  }

  const [user, setUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!isAddMode) {
      // get user and set form fields
      userService.getById(id).then((user) => {
        const fields = ["title", "firstName", "lastName", "email", "role"];
        fields.forEach((field) => setValue(field, user[field]));
        setUser(user);
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
      <h1>{isAddMode ? "Add User" : "Edit User"}</h1>
      <div className="form-row">
        <div className="form-group col">
          <label>Title</label>
          <select
            name="title"
            ref={register}
            className={`form-control ${errors.title ? "is-invalid" : ""}`}
          >
            <option value=""></option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Ms">Ms</option>
          </select>
          <div className="invalid-feedback">{errors.title?.message}</div>
        </div>
        <div className="form-group col-5">
          <label>First Name</label>
          <input
            name="firstName"
            type="text"
            ref={register}
            className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.firstName?.message}</div>
        </div>
        <div className="form-group col-5">
          <label>Last Name</label>
          <input
            name="lastName"
            type="text"
            ref={register}
            className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.lastName?.message}</div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-7">
          <label>Email</label>
          <input
            name="email"
            type="text"
            ref={register}
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
        </div>
        <div className="form-group col">
          <label>Role</label>
          <select
            name="role"
            ref={register}
            className={`form-control ${errors.role ? "is-invalid" : ""}`}
          >
            <option value=""></option>
            <option value="User">User</option>
            <option value="Admin">Admin</option>
          </select>
          <div className="invalid-feedback">{errors.role?.message}</div>
        </div>
      </div>
      {!isAddMode && (
        <div>
          <h3 className="pt-3">Change Password</h3>
          <p>Leave blank to keep the same password</p>
        </div>
      )}
      <div className="form-row">
        <div className="form-group col">
          <label>
            Password
            {!isAddMode &&
              (!showPassword ? (
                <span>
                  {" "}
                  -{" "}
                  <a
                    onClick={() => setShowPassword(!showPassword)}
                    className="text-primary"
                  >
                    Show
                  </a>
                </span>
              ) : (
                <em> - {user.password}</em>
              ))}
          </label>
          <input
            name="password"
            type="password"
            ref={register}
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.password?.message}</div>
        </div>
        <div className="form-group col">
          <label>Confirm Password</label>
          <input
            name="confirmPassword"
            type="password"
            ref={register}
            className={`form-control ${
              errors.confirmPassword ? "is-invalid" : ""
            }`}
          />
          <div className="invalid-feedback">
            {errors.confirmPassword?.message}
          </div>
        </div>
      </div>
      <div className="form-group">
        <button
          type="submit"
          disabled={formState.isSubmitting}
          className="btn btn-primary"
        >
          {formState.isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          Save
        </button>
        <Link to={isAddMode ? "." : ".."} className="btn btn-link">
          Cancel
        </Link>
      </div>
    </form>
  );
}

export { AddEdit };*/
