/* eslint-disable */
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import NavComp from "../../Components/Header/NavComp";
import {
  getComp,
  listvagacomp,
  preenchevaga,
} from "../../actions/companyActions";
import { Context } from "../../context/auth";
import DialogComponent from "../../Components/Dialog/Dialog";
import AlertComponent from "../../Components/Alert/Alert";
import LoadingCompoent from "../../Components/Loading/Loading";
import NavAdmin from "../../Components/Header/NavAdmin";
import { getVagasCompany } from "../../actions/adminActions";
import ViewVaga from "./view/Vagas";

export default function DashboardAdmin() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [vaga, setVaga] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAddVaga, setIsOpenAddVaga] = useState(false);
  const [isOpenViewVaga, setIsOpenViewVaga] = useState(false);
  const [isOpenCandidatoVaga, setIsOpenCandidatoVaga] = useState(false);
  const [dateVaga, setDateVaga] = useState("");
  const [listVagaCandidate, setCandaditeVaga] = useState([]);
  const [pages, setPages] = useState(1);
  const [RecallComponent, setReacall] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const [listFilter, setListFilter] = useState([]);
  const [token, setToken] = useState();
  const [comp, setComp] = useState("");
  const [vagasCompany, setVagasCompany] = useState("");
  const [vagasView, setVagaView] = useState("");

  const { handleLogout } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    async function compDate() {
      const isLocalStorage = JSON.parse(localStorage.getItem("_myadmin_"));

      const token = isLocalStorage.token;

      setToken(token);

      setLoading(true);
      const getVagasAprovadas = await getVagasCompany(token);
      await setVagasCompany(getVagasAprovadas);
      setLoading(false);
    }
    compDate();
  }, []);

  /*  useEffect(() => {
    async function compDate() {
      const isLocalStorage = JSON.parse(localStorage.getItem("_mycomp_"));

      const token = isLocalStorage.token;

      setToken(token);

      setLoading(true);
      const getCompDate = await getComp(token);
      const getVagasAprovadas = await listvagacomp(token);

      await setComp(getCompDate);
      await setVagasAprovadas(getVagasAprovadas);
      setLoading(false);
    }
    compDate();
  }, []);*/

  useEffect(() => {
    async function compDate() {
      const isLocalStorage = JSON.parse(localStorage.getItem("_myadmin_"));

      const token = isLocalStorage.token;

      setToken(token);

      setLoading(true);
      const getVagasAprovadas = await getVagasCompany(token);
      await setVagasCompany(getVagasAprovadas.listpage);
      const CompanyList = getVagasAprovadas.DateCompany;
      await setComp(CompanyList);
      setLoading(false);
    }
    compDate();
  }, [RecallComponent]);

  /*useEffect(() => {
async function userDate() {
      const isLocalStorage = JSON.parse(localStorage.getItem("_myuser_"));
      if (!isLocalStorage) {
        const comp = 2;
        handleLogout(comp);
        navigate("/login");
      } else {
        const token = isLocalStorage.token;
        const x = await validateToken(token);
        if (!x) {
          const comp = 1;
          handleLogout(comp);
          navigate("/login");
        }
        setToken(token);

        const getCompDate = await getComp(token);

        await setComp(getCompDate);
      }
    }
    userDate();
  }, [RecallComponent]);*/

  function openInformacoes() {
    setIsOpen(true);
  }

  function openAdicionarVagas() {
    setIsOpenAddVaga(true);
  }

  async function marcarVagaPreenchida(idVaga, p) {
    const resp = await preenchevaga(idVaga, p, token);
    setReacall(Math.random());
  }

  function openEditarVagas(id) {
    const result = vagasaprovadas.filter((vagas) => vagas._id === id);
    setVaga(result);
    setIsOpenEditarVaga(true);
  }

  function getNameComp(idComp) {
    if (comp.length === 0 || comp[0] == null) {
      return;
    }

    for (var i = 0; i <= comp.length; i++) {
      if (comp[i]._id === idComp) {
        return comp[i].nome;
      } else {
        return;
      }
    }
  }

  async function closeModal() {
    setReacall(Math.random());
    setIsOpen(false);
  }

  async function closeModalAddVaga() {
    setReacall(Math.random());
    setIsOpenAddVaga(false);
  }

  async function closeModalEditarVaga() {
    setReacall(Math.random());
    setIsOpenViewVaga(false);
  }

  function openCandidatoVagas(id) {
    const result = vagasaprovadas.filter((vagas) => vagas._id === id);
    setVaga(result);
    setIsOpenCandidatoVaga(true);
  }

  async function closeModalCandidatoVagas() {
    setReacall(Math.random());
    setIsOpenCandidatoVaga(false);
  }

  function openViewVagas(id) {
    const result = vagasCompany.docs.filter((vaga) => vaga._id === id);
    setVagaView(result);
    setIsOpenViewVaga(true);
  }

  return (
    <>
      <DialogComponent
        openDialog={isOpenViewVaga}
        closeModal={closeModalEditarVaga}
        title={"Visualizando Vaga"}
        component={
          <ViewVaga vagaDate={vagasView} close={closeModalEditarVaga} />
        }
      />

      <div className="h-screen flex flex-col">
        <div className="flex flex-1">
          <NavAdmin />
          <main className="flex-1 bg-gray-400/10 p-4">
            <AlertComponent
              message={
                "Caso precise de ajuda e/ou suporte, entre em contato conosco atraves das redes sociais, como Facebook, Instagram."
              }
            />
            <div className="grid grid-cols-1 gap-4 mt-10">
              <div className="bg-white">
                <div className="p-3">
                  <div className="flex flex-row">
                    <img
                      src="https://i.ibb.co/LP2cKrj/user.png"
                      className="w-12 h-12"
                    />
                    <p className="p-3 text-xl font-semibold">
                      Vagas de empresas para aprovar
                      <small className="flex text-slate-600 text-sm">
                        Aqui estão as vagas para verificar empresa e verificar
                        se é real
                      </small>
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 p-6">
                  {loading ? (
                    <LoadingCompoent />
                  ) : vagasCompany === "" ||
                    vagasCompany.docs === 0 ||
                    vagasCompany.docs === undefined ||
                    vagasCompany === undefined ? (
                    ""
                  ) : (
                    vagasCompany.docs.map((vagas) => (
                      <>
                        <div className="flex gap-8">
                          <div className="flex flex-col">
                            <p className="text-md font-light">
                              {vagas.titulovaga}
                            </p>
                            <p className="text-md font-light">
                              {getNameComp(vagas.Company)}
                            </p>
                            <small className="text-md font-semibold">
                              {vagas.aprovada === 1
                                ? "Pendente de aprovação"
                                : vagas.aprovada === 2
                                ? "Aprovada"
                                : "Reprovada"}
                            </small>
                          </div>

                          <div className="flex flex-1 gap-4 justify-end">
                            <button
                              onClick={() => openViewVagas(vagas._id)}
                              className="flex gap-4 font-medium text-blue-me"
                            >
                              Verificar vaga
                            </button>
                          </div>
                        </div>
                      </>
                    ))
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
