import { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorsForm from "../../Components/FormError/Errors";
import { useNavigate, useParams } from "react-router-dom";
import AlertComponent from "../../Components/Alert/Alert";
import NavIncio from "../../Components/Header/NavInico";
import LoadingCompoent from "../../Components/Loading/Loading";
import { ResetForgotPasswordComp } from "../../actions/companyActions";

let ResetPasswordComp = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [statusMessage, setStatusMessage] = useState(null);

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  function openMessage(status) {
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
    setIsOpen(true);
  }

  const parametro = useParams();

  const onSubmit = async (data) => {
    setLoading(true);
    const resp = await ResetForgotPasswordComp(data, parametro.token);
    if (resp.status === 200) {
      navigate("/sucessosenha");
    } else {
      openMessage();
      setStatusMessage(false);
      setMessage(resp.response.data[0].message);
    }

    setLoading(false);
  };

  return (
    <div className="bg-white">
      <NavIncio />
      <div className="w-screen h-screen  items-center justify-center">
        <div className="bg-white flex min-h-full flex-1 flex-col justify-center  px-6 py-12 lg:px-8">
          <div>
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl">
                Recuperção da sua senha
              </h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Digite sua nova senha, em seguita digite a mesma novamente e
                clique em confirmar.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="senha_nv"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Nova Senha
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="senha_nv"
                    name="senha_nv"
                    type="password"
                    {...register("senha_nv", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.senha_nv?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.senha_nv?.type === "minLength" && (
                    <ErrorsForm
                      text={"Ops! Sua senha tem que ter no minimo 8 caracter."}
                    />
                  )}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="senha_nv_repeat"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Nova Senha novamente
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="senha_nv_repeat"
                    name="senha_nv_repeat"
                    type="password"
                    {...register("senha_nv_repeat", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.senha_nv_repeat?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                  {errors?.senha_nv_repeat?.type === "minLength" && (
                    <ErrorsForm
                      text={"Ops! Sua senha tem que ter no minimo 8 caracter."}
                    />
                  )}
                  {watch("senha_nv_repeat") !== watch("senha_nv") &&
                  getValues("senha_nv_repeat") ? (
                    <ErrorsForm
                      text={"Ops! Sua senha tem  que ser igual a de cima."}
                    />
                  ) : null}
                </div>
              </div>
              <div className="mt-10 mb-10">
                {isOpen ? (
                  <AlertComponent message={message} status={statusMessage} />
                ) : (
                  <button
                    type="submit"
                    className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                  >
                    {loading ? <LoadingCompoent /> : "Confirmar"}
                  </button>
                )}
              </div>
            </form>
            <div className="mt-10 mb-10">
              <a
                type="button"
                href="/esqueceusenha"
                className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
              >
                Recuperar senha novamente
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordComp;
