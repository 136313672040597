import axios from "axios";
import authorization from "../auth/authRouter";

const BASE_URL_VAGA = process.env.REACT_APP_URL_VAGA;

export async function getlistVagas(page) {
  const response = await axios
    .get(`${BASE_URL_VAGA}/listavagas?page=${page}`)
    .then((resp) => {
      return resp;
    })
    .catch((e) => {
      return e;
    });

  if (!response) {
    return;
  } else {
    return response.data;
  }
}

export async function updateVagaComp(idVaga, date, token) {
  const headers = authorization(token);
  const resp = await axios.put(`${BASE_URL_VAGA}/alterarvaga/${idVaga}`, date, {
    headers: headers,
  });
  if (!resp) {
    return;
  } else {
    return resp.data;
  }
}

export async function UsersCandidateVaga(idVaga, token) {
  const headers = authorization(token);
  const response = await axios.get(`${BASE_URL_VAGA}/usercandidate/${idVaga}`, {
    headers: headers,
  });

  if (!response) {
    return;
  } else {
    return response.data;
  }
}

/*
export async function dateVagas(token, idVaga) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL_VAGA}/datevaga/${idVaga}`, {
    headers: headers,
  });
  return {
    type: "RETURN_DATE_VAGA",
    payload: request,
  };
}

export function updateVagaComp(idVaga, date, token) {
  const headers = authorization(token);
  return async (dispatch) => {
    await axios
      .put(`${BASE_URL_VAGA}/alterarvaga/${idVaga}`, date, { headers: headers })
      .then((resp) => {
        toastr.success("Ok!", "Vaga Alterada com sucesso !");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error(
            "Ops!",
            "Estamos com problemas no servidores  Aguarde !"
          );
        } else {
          toastr.error(
            "Ops!",
            "Estamos com problemas no servidores  Aguarde !"
          );
        }
      });
  };
}

export async function listOptionVaga(token, page) {
  const headers = authorization(token);
  const request = await axios.get(
    `${BASE_URL_VAGA}/listavagascomp?page=${page}`,
    { headers: headers }
  );
  return {
    type: "RETURN_LIST_OPTION_VAGA",
    payload: request,
  };
}

export async function UsersCandidateVaga(idVaga, token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL_VAGA}/usercandidate/${idVaga}`, {
    headers: headers,
  });
  return {
    type: "RETURN_LIST_VAGA_CANDIDATE",
    payload: request,
  };
}

export function NameUser(token, idUser) {
  const headers = authorization(token);
  const request = axios.get(`${BASE_URL_VAGA}/nameuser/${idUser}`, {
    headers: headers,
  });
  return {
    type: "RETURN_NAME_USER",
    payload: request,
  };
}

export async function ListVagasCompanyinURL(idComp) {
  const request = await axios.get(`${BASE_URL_TESTE}/minhasvagas/${idComp}`);
  return {
    type: "RETURN_LIST_VAGA_COMPANY_URL",
    payload: request,
  };
}
*/
