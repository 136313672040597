import ErrorsForm from "../../Components/FormError/Errors";
import NavIncio from "../../Components/Header/NavInico";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/auth";
import AlertComponent from "../../Components/Alert/Alert";

export default function LoginAdmin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { handleLogin } = useContext(Context);

  const navigate = useNavigate();

  const [message, setMessage] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmitAdmin = async (data) => {
    setLoading(true);
    try {
      const admin = 3;
      await handleLogin(data, admin);
      navigate("/admin");
    } catch (e) {
      if (!e) {
        setMessage(
          "Ops! estamos com problemas em nossos serviços,tente novamente."
        );
        openMessage();
      } else {
        if (e.response.status === 404) {
          setMessage(e.response.data[0].message);
          setStatusMessage(false);
          openMessage();
        }
      }
    }
    setLoading(false);
  };

  function openMessage(status) {
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
    setIsOpen(true);
  }

  return (
    <>
      <div>
        <NavIncio />
      </div>
      <div className="w-screen h-screen  items-center justify-center">
        <div className="bg-white flex min-h-full flex-1 flex-col justify-center  px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Login
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmitAdmin)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.email_user?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}

                  {errors?.email_user?.type === "pattern" && (
                    <ErrorsForm text={"preencha o email corretamente"} />
                  )}
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Senha
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="senha"
                    name="senha"
                    type="password"
                    {...register("senha", { required: true })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.senha_user?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                </div>
                {isOpen ? (
                  <div className="pt-3">
                    <AlertComponent status={statusMessage} message={message} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {loading ? (
                <button
                  disabled
                  className="flex w-full justify-center rounded-md bg-blue-me px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-me focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Aguarde...
                </button>
              ) : (
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-me px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-me focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Entrar
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
