import React from "react";
import NavIncio from "../../Components/Header/NavInico";

function Sobre() {
  return (
    <>
      <NavIncio />
      <div className="container mx-auto mt-24">
        <div class="">
          <div class="">
            <div class="lg:pr-4">
              <div class="">
                <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Nossa história
                </h1>
                <p class="mt-6 text-xl leading-8 text-gray-700">
                  A história do <b>BlueJob</b> começa com a visão de ajudar
                  pessoas a encontrar empregos de forma mais eficiente e
                  simplificada. Fundada por um empreendedores com experiência em
                  tecnologia, a plataforma foi concebida como uma solução para
                  as dificuldades enfrentadas por candidatos e empregadores no
                  processo de contratação.
                  <p class="mt-6 text-xl leading-8 text-gray-700">
                    A equipe por trás do <b>BlueJob</b> percebeu que o mercado
                    de trabalho estava evoluindo rapidamente, mas os métodos
                    tradicionais de busca de emprego ainda eram complexos e
                    demorados. Os candidatos muitas vezes tinham dificuldade em
                    encontrar vagas relevantes, enquanto os empregadores
                    enfrentavam desafios para atrair talentos qualificados.
                  </p>
                  <p class="mt-6 text-xl leading-8 text-gray-700">
                    Com essa percepção, o <b>BlueJob</b> foi lançado com o
                    objetivo de simplificar o processo de recrutamento,
                    conectando candidatos talentosos a oportunidades de emprego
                    adequadas às suas habilidades e experiências. A plataforma
                    foi projetada para ser intuitiva e fácil de usar, oferecendo
                    recursos poderosos de busca de emprego e ferramentas para
                    empregadores gerenciarem seus processos de contratação de
                    forma eficiente.
                  </p>
                  <p class="mt-6 text-xl leading-8 text-gray-700">
                    Inicialmente estamso realizando nossos serviço na região
                    norte do estado do Paraná e é claro que em breve iremos
                    estapandir para demais regioes e por todos os estados
                    Brasileiros.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sobre;
