import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { useForm } from "react-hook-form";
import ErrorsForm from "../../../Components/FormError/Errors";
import InputMask from "react-input-mask";
import { reduxForm } from "redux-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { OrbitIcon } from "lucide-react";
import { postCurso, postExp, putCurso } from "../../../actions/userActions";
import authSearchUser from "../../../Service/authSearchUser";
import AlertComponent from "../../../Components/Alert/Alert";
import LoadingCompoent from "../../../Components/Loading/Loading";

const BASE_URL = process.env.REACT_APP_URL_USUARIO;

let EditarEducacao = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [valueEstado, setEstado] = useState("select");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [cursoAtual, setCusoAtual] = useState();
  const [statusMessage, setStatusMessage] = useState();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  function closeModal(value) {
    setIsOpen(false);
  }

  function openMessage(status) {
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
    setIsOpen(true);
  }

  //const [curso, setCurso] = useState();

  const handleChange = (e) => {
    const estado = e.target.options[e.target.selectedIndex].id;
    setEstado(estado);
  };

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    async function editFormCurso() {
      const { nomecurso, localcurso, datainiciocurso, datafimcurso, atual } =
        props.curDate[0];
      setValue("nomecurso", nomecurso);
      setValue("localcurso", localcurso);
      setValue("datainiciocurso", datainiciocurso);
      setValue("datafimcurso", datafimcurso);
      setValue("atual", atual);
      setCusoAtual(atual);
    }
    editFormCurso();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const token = await authSearchUser(
      props.handleLogout,
      props.navigate,
      props.valid
    );

    if (token === undefined || !token) {
      return;
    }
    const resp = await putCurso(data, token, props.curDate[0]._id);
    openMessage();
    setStatusMessage(true);
    setMessage(resp);
    setLoading(false);
  };

  return (
    <div className="relative isolate px-3  lg:px-8">
      <div className="flex flex-col">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="mx-auto max-w-xl mt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <label className="block text-sm font-semibold leading-6 text-gray-900">
                Nome do curso
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  id="nomecurso"
                  {...register("nomecurso", {
                    required: true,
                  })}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors?.nomecurso?.type === "required" && (
                  <ErrorsForm text={"Ops! Faltou algo aqui."} />
                )}
              </div>
            </div>
            <div>
              <label className="block text-sm font-semibold leading-6 text-gray-900">
                Local do curso
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  id="localcurso"
                  {...register("localcurso", {
                    required: true,
                  })}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors?.localcurso?.type === "required" && (
                  <ErrorsForm text={"Ops! Faltou algo aqui."} />
                )}
              </div>
            </div>
            <div>
              <label className="block text-sm font-semibold leading-6 text-gray-900">
                Data inicio
              </label>
              <div className="mt-2.5">
                <input
                  type="date"
                  id="datainiciocurso"
                  {...register("datainiciocurso", {
                    required: true,
                  })}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors?.datainiciocurso?.type === "required" && (
                  <ErrorsForm text={"Ops! Faltou algo aqui."} />
                )}
              </div>
            </div>

            <div>
              <label className="mt-2 block text-sm font-semibold leading-6 text-gray-900">
                Voce ainda esta realizando esse curso/faculdade ?
              </label>
              <div className="mt-1">
                <div class="flex items-center ps-4 border mt-3 border-gray-200 rounded dark:border-gray-700">
                  <input
                    id="atualSim"
                    type="radio"
                    {...register("atual", {})}
                    onClick={(e) => setCusoAtual(true)}
                    value="true"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="bordered-radio-1"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Sim
                  </label>
                </div>
                <div class="flex items-center ps-4 border mt-3 border-gray-200 rounded dark:border-gray-700">
                  <input
                    id="atualNao"
                    type="radio"
                    value="false"
                    {...register("atual", {})}
                    onClick={(e) => setCusoAtual(false)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="bordered-radio-2"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Não
                  </label>
                </div>
              </div>
            </div>
            {cursoAtual === true || cursoAtual === "true" ? (
              ""
            ) : (
              <div>
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Data termino do curso
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    id="datafimcurso"
                    {...register("datafimcurso", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.datafimcurso?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                </div>
              </div>
            )}
            <div className="mt-10 mb-10">
              {isOpen ? (
                <AlertComponent message={message} status={statusMessage} />
              ) : (
                <button
                  type="submit"
                  className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                >
                  {loading ? <LoadingCompoent /> : "Editar"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditarEducacao;
