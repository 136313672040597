import ErrorsForm from "../../Components/FormError/Errors";
import NavIncio from "../../Components/Header/NavInico";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/auth";
import AlertComponent from "../../Components/Alert/Alert";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { handleLogin } = useContext(Context);

  const navigate = useNavigate();

  const [message, setMessage] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [userOrComp, setUserOrComp] = useState(1);

  const onSubmitUser = async (data) => {
    setLoading(true);
    try {
      const user = 1;
      await handleLogin(data, user);
      navigate("/curriculo");
    } catch (e) {
      if (!e) {
        setMessage(
          "Ops! estamos com problemas em nossos serviços,tente novamente."
        );
        openMessage();
      } else {
        if (e.response.status === 400) {
          setMessage(e.response.data[0].message);
          setStatusMessage(false);
          openMessage();
        }
      }
    }
    setLoading(false);
  };

  const onSubComp = async (data) => {
    setLoading(true);
    try {
      const user = 2;
      await handleLogin(data, user);
      navigate("/dashboard");
    } catch (e) {
      if (!e) {
        setMessage(
          "Ops! estamos com problemas no nosso serviços,tente novamente."
        );
        openMessage();
      } else {
        if (e.response.status === 400) {
          setMessage(e.response.data[0].message);
          setStatusMessage(false);
          openMessage();
          //setUserOrComp(1);
        }
      }
    }

    setUserOrComp(1);
    setLoading(false);
  };

  function openMessage(status) {
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
    setIsOpen(true);
  }

  return (
    <>
      <div>
        <NavIncio />
      </div>
      <div className="w-screen h-screen  items-center justify-center">
        <div className="bg-white flex min-h-full flex-1 flex-col justify-center  px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Login
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className="space-y-6"
              onSubmit={handleSubmit(
                userOrComp === 1 ? onSubmitUser : onSubComp
              )}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.email_user?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}

                  {errors?.email_user?.type === "pattern" && (
                    <ErrorsForm text={"preencha o email corretamente"} />
                  )}
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Senha
                  </label>
                  <div className="text-sm">
                    <a
                      href="/esqueceusenha"
                      className="font-semibold text-blue-me hover:text-blue-me"
                    >
                      Esqueceu sua senha?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="senha"
                    name="senha"
                    type="password"
                    {...register("senha", { required: true })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.senha_user?.type === "required" && (
                    <ErrorsForm text={"Ops! Faltou algo aqui."} />
                  )}
                </div>
                {isOpen ? (
                  <div className="pt-3">
                    <AlertComponent status={statusMessage} message={message} />
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-10">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Quem é voce?
                  </label>
                  <div class="flex items-center ps-4 border mt-3 border-gray-200 rounded dark:border-gray-700">
                    <input
                      id="bordered-radio-1"
                      type="radio"
                      checked
                      value=""
                      onClick={(e) => setUserOrComp(1)}
                      name="bordered-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-radio-1"
                      className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Candidato
                    </label>
                  </div>
                  <div class="flex items-center ps-4 border mt-3 border-gray-200 rounded dark:border-gray-700">
                    <input
                      id="bordered-radio-2"
                      type="radio"
                      value=""
                      onClick={(e) => setUserOrComp(2)}
                      name="bordered-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-radio-2"
                      className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Empresa
                    </label>
                  </div>
                </div>
              </div>
              {loading ? (
                <button
                  disabled
                  className="flex w-full justify-center rounded-md bg-blue-me px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-me focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Aguarde...
                </button>
              ) : (
                <button
                  type="submit"
                  className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                >
                  Entrar
                </button>
              )}
            </form>
          </div>
          <p className="mt-10 text-center text-sm text-gray-500">
            Ainda não possui cadastro como candidato?{" "}
            <a
              href="/cadastrocandidato"
              className="font-semibold leading-6 text-blue-me hover:text-blue-me"
            >
              cadastre-se
            </a>
          </p>
          <p className="mt-3 text-center text-sm text-gray-500"> ou </p>
          <p className="mt-3 text-center text-sm text-gray-500">
            Ainda não possui cadastro para sua empresa?{" "}
            <a
              href="/cadastroempresa"
              className="font-semibold leading-6 text-blue-me hover:text-blue-me"
            >
              cadastre-se
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
