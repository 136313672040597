import NavIncio from "../../Components/Header/NavInico";
import {
  Instagram,
  Facebook,
  Mail,
  Rocket,
  DollarSign,
  MessageCircleIcon,
  Hand,
} from "lucide-react";

export default function Home() {
  const features = [
    {
      name: "Preenchimento Ágil de Vagas",
      description:
        "Reduzimos o tempo de contratação com processos rápidos e assertivos.",
      icon: Rocket,
    },
    {
      name: "Zero Custos, Mais Resultados",
      description:
        "Otimização de recursos para um processo de seleção mais econômico e eficaz.",
      icon: DollarSign,
    },
    {
      name: "Suporte Especializado e Consultivo",
      description:
        "Atendimento ágil e resolutivo para garantir uma experiência sem complicações.",
      icon: MessageCircleIcon,
    },
    {
      name: "Empregos na Palma da Mão",
      description:
        "Cadastre-se em minutos e encontre vagas compatíveis com seu perfil de forma rápida e fácil .",
      icon: Hand,
    },
  ];
  return (
    <div className="bg-white">
      <NavIncio />
      <div className="relative isolate px-3 pt-4 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-32">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-2 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Acesse aqui nosso termos e regras.{" "}
              <a
                href="/termosecondicoes"
                className="font-semibold text-blue-me"
              >
                <span className="absolute inset-0" aria-hidden="true" />
                ir para termos e regras <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
              Bem vindo ao BlueJob
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Você irá encontrar diversas oportunidades de emprego de uma forma
              simples, rápida e fácil.
            </p>
            <div className="mt-5 flex items-center justify-center gap-x-6">
              <a
                href="/cadastrocandidato"
                className="rounded-md bg-blue-me px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-me focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Cadastre-se candidato
              </a>
              <a
                href="/cadastroempresa"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cadastre sua empresas <span aria-hidden="true">→</span>
              </a>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-16">
                    <dt className="text-base/7 font-semibold text-gray-900">
                      <div className="absolute top-0 left-0 flex size-10 items-center justify-center rounded-lg bg-blue-me">
                        <feature.icon
                          aria-hidden="true"
                          className="size-6 text-white"
                        />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-base/7 text-gray-600">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <section class="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
              <div class="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,var(--color-indigo-100),white)] opacity-20"></div>
              <div class="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white ring-1 shadow-xl shadow-indigo-600/10 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
              <div class="mx-auto max-w-2xl lg:max-w-4xl">
                <figure class="mt-10">
                  <blockquote class="text-center text-xl/8 font-semibold text-gray-900 sm:text-2xl/9">
                    <p>
                      “Nos primeiros passos de uma empresa, o que importa não é
                      o tamanho do sonho, mas a coragem de começar. Cada desafio
                      é uma oportunidade de crescimento, e cada erro, uma lição
                      para construir algo mais forte. Acredite no processo, seja
                      resiliente e lembre-se: o sucesso não é um destino, é uma
                      jornada que se constrói com dedicação, inovação e paixão.”
                    </p>
                  </blockquote>
                  <figcaption class="mt-10">
                    <div class="mt-4 flex items-center justify-center space-x-3 text-base">
                      <div class="font-semibold text-gray-900">
                        Renan Carlos de Melo Nunes
                      </div>
                      <svg
                        viewBox="0 0 2 2"
                        width="3"
                        height="3"
                        aria-hidden="true"
                        class="fill-gray-900"
                      >
                        <circle cx="1" cy="1" r="1" />
                      </svg>
                      <div class="text-gray-600">CEO BlueJob</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </section>
          </div>
        </div>
        <footer class="w-full">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-3 md:gap-8 py-10 max-sm:max-w-sm max-sm:mx-auto gap-y-8">
              <div class="col-span-full mb-10 lg:col-span-2 lg:mb-0">
                <p className="text-2xl font-semibold">BlueJob</p>
                <p class="py-8 text-sm text-gray-500 lg:max-w-xs text-center lg:text-left">
                  Quer ajudar nossa empresa, dar desafios, aumentar sua
                  visibilidade junto a nós entre em contato atrave do email
                  abaixo.
                </p>
                <p className="py-2.5 px-5 h-9 block w-fit bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0">
                  contato@bluejob.com.br
                </p>
              </div>
              <div class="lg:mx-auto text-left ">
                <h4 class="text-lg text-gray-900 font-medium mb-7">Inicio</h4>
                <ul class="text-sm  transition-all duration-500">
                  <li class="mb-6">
                    <a
                      href="/sobrenos"
                      class="text-gray-600 hover:text-gray-900"
                    >
                      Quem somos?
                    </a>
                  </li>
                  <li class="mb-6">
                    <a
                      href="/termosecondicoes"
                      class=" text-gray-600 hover:text-gray-900"
                    >
                      Termos e condições
                    </a>
                  </li>
                </ul>
              </div>

              <div class="lg:mx-auto text-left">
                <h4 class="text-lg text-gray-900 font-medium mb-7">Blogs</h4>
                <ul class="text-sm  transition-all duration-500">
                  <li class="mb-6">
                    <p className="text-gray-600 hover:text-gray-900">
                      Em breve
                    </p>
                  </li>
                </ul>
              </div>
              <div class="lg:mx-auto text-left">
                <h4 class="text-lg text-gray-900 font-medium mb-7">
                  Redes Sociais e suporte
                </h4>
                <ul class="text-sm  transition-all duration-500">
                  <li class="mb-6">
                    <a
                      href="https://www.facebook.com/MeEmpregaOficial/"
                      class="text-gray-600 gap-3 hover:text-gray-900 flex"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Facebook />
                      Facebook
                    </a>
                  </li>
                  <li className="mb-6">
                    <a
                      href="https://www.instagram.com/meempregaoficial/"
                      class="text-gray-600 gap-3 hover:text-gray-900 flex"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagram />
                      Instagram
                    </a>
                  </li>
                  <li className="mb-6">
                    <p class="text-gray-600 gap-3 hover:text-gray-900 flex">
                      <Mail />
                      suporte@bluejob.com.br
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="py-7 border-t border-gray-200">
              <div class="flex items-center justify-center flex-col lg:justify-between lg:flex-row">
                <span class="text-sm text-gray-500 ">
                  ©<a href="meemprega.com">BlueJob</a> 2025, Todos os direitos
                  reservados.
                </span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
