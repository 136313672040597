import React from "react";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
// Middleware in applicantion
import reducerMain from "./main/reducers";
import promise from "redux-promise";
import multi from "redux-multi";
import thunk from "redux-thunk";

//Import middleware,router,history
//import { routerMiddleware } from "connected-react-router";
import { BrowserRouter, Route, Router } from "react-router-dom";
import history from "./main/history";
import AlertComponent from "./Components/Alert/Alert";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
