import axios from "axios";
import authorization from "../auth/authRouter";

const BASE_URL = process.env.REACT_APP_URL_COMPANY;
const BASE_URL_VAGA = process.env.REACT_APP_URL_VAGA;

export async function createCompany(values) {
  await axios
    .post(`${BASE_URL}/cadastro`, values)
    .then((resp) => {
      return resp.data.forEach((mg) => mg.message);
    })
    .catch((e) => {
      if (!e.response) {
        return "error";
      } else {
        return e.response.data.forEach((mg) => mg.message);
      }
    });
}

export async function getComp(token) {
  const headers = authorization(token);
  const response = await axios.get(`${BASE_URL}/comp`, { headers: headers });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function listvagacomp(token) {
  const headers = authorization(token);
  const response = await axios.get(`${BASE_URL_VAGA}/vagainaproved`, {
    headers: headers,
  });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function postvaga(value, token) {
  const headers = authorization(token);

  const response = await axios.post(`${BASE_URL_VAGA}/criarvagas`, value, {
    headers: headers,
  });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function updateCompany(values, token) {
  const headers = authorization(token);
  const response = await axios.put(`${BASE_URL}/alterar`, values, {
    headers: headers,
  });
  if (!response) {
    return false;
  } else {
    return response.data;
  }
}

export async function preenchevaga(idVaga, preenchida, token) {
  const headers = authorization(token);
  const resp = await axios.put(
    `${BASE_URL_VAGA}/preenchevaga/${idVaga}/${preenchida}`,
    {
      headers: headers,
    }
  );

  if (!resp) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return resp.data;
  }
}

export default async function forgotPasswordComp(values) {
  const response = await axios
    .post(`${BASE_URL}/forgetpassaword`, values)
    .then((resp) => {
      return resp;
    })
    .catch((e) => {
      if (!e.response) {
        return e;
      } else {
        return e;
      }
    });

  if (!response) {
    return "Error";
  } else {
    return;
  }
}

export async function ResetForgotPasswordComp(values, token) {
  const response = await axios
    .post(`${BASE_URL}/resetpassword`, { values, token })
    .then((resp) => {
      return resp;
    })
    .catch((e) => {
      if (!e.response) {
        return e;
      } else {
        return;
      }
    });

  if (!response) {
    return "Error";
  } else {
    return response;
  }
}

export async function validateToken(token) {
  const response = await axios
    .post(`${BASE_URL}/validtokencomp`, { token })
    .then((resp) => {
      return resp.data.valid;
    })
    .catch((e) => {
      if (!e.response) {
        return false;
      } else {
        return true;
      }
    });

  if (!response) {
    return false;
  } else {
    return response;
  }
}

/*
export function createComp(values) {
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/cadastro`, values)
      .then((resp) => {
        toastr.success("Ok!", "Empresa Cadastrada Com Sucesso,Realize o login");
        dispatch([resetForm("FormCompany"), push("/login")]);
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          e.response.data.forEach((mg) => toastr.error("Falho", mg.message));
        }
      });
  };
}

//Alterar usuario Company
export function updateCompany(values, token) {
  return (dispatch) => {
    const headers = authorization(token);
    axios
      .put(`${BASE_URL}/alterar`, values, { headers: headers })
      .then((resp) => {
        toastr.success("Obrigado!", "Alterado Com Sucesso !");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!!", "Estamos com problemas no servidor Aguarde !");
        } else {
          e.response.data.forEach((mg) => toastr.error("Ops!!", mg.message));
        }
      });
  };
}

export function postvaga(value, token) {
  const headers = authorization(token);
  return async (dispatch) => {
    await axios
      .post(`${BASE_URL_VAGA}/criarvagas`, value, { headers: headers })
      .then((resp) => {
        toastr.success(
          "Obrigado",
          "Sua vaga foi para analise aguarde ela ser aprovada"
        );
        dispatch(resetForm("FormCadVagas"));
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error(
            "Ops!",
            "Erro ao Cadastrar vaga (Problemas no servidor)"
          );
        }
        e.response.data.forEach((mg) => toastr.error("Ops!!", mg.message));
      });
  };
}

export async function listvagacomp(token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL_VAGA}/vagainaproved`, {
    headers: headers,
  });
  return {
    type: "LIST_VAGA_COMP",
    payload: request,
  };
}

export function preenchevaga(idVaga, preenchida, token) {
  const headers = authorization(token);
  return async (dispatch) => {
    await axios
      .put(`${BASE_URL_VAGA}/preenchevaga/${idVaga}/${preenchida}`, {
        headers: headers,
      })
      .then((resp) => {
        resp.data.forEach((mg) => toastr.success("Ok!", mg.message));
      })
      .catch((e) => {
        e.response.data.forEach((mg) => toastr.error("Ops!!", mg.message));
      });
  };
}

//Download
export async function downloadcurriculoComp(idUser, token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL}/downloadcurriculo/${idUser}`, {
    responseType: "arraybuffer",
    headers: headers,
  });

  return {
    type: "DOWNLOAD_CURRICULO_USER_COMP",
    payload: request,
  };
}

//getCurriculo
export async function getCurriculoUserComp(idUser, token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL}/curriculocandidate/${idUser}`, {
    headers: headers,
  });
  return {
    type: "RETURN_THEN_CURRICULO_COMP",
    payload: request,
  };
}

//GetCurriculoCandidate
export function getCurriculoCandidateComp(idUser, token) {
  const headers = authorization(token);
  const request = axios.get(`${BASE_URL}/meucurriculo/${idUser}`, {
    headers: headers,
  });
  return {
    type: "RETURN_CURRICULO_COMP",
    payload: request,
  };
}

export default function forgotPasswordComp(values) {
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/forgetpassaword`, values)
      .then((resp) => {
        dispatch([resetForm("FormCompany"), push("/message_forgot_password")]);
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          dispatch([
            resetForm("FormCompany"),
            push("/message_forgot_password"),
          ]);
        }
      });
  };
}

export function ResetPasswordComp(values, token) {
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/resetpassword`, { values, token })
      .then((resp) => {
        dispatch([push("/success_forgot_password")]);
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error(
            "Ops!!",
            "Algo de errado ocorreu solicite alteração da senha novamente!"
          );
          dispatch([push("/login")]);
        } else {
          dispatch([push("/")]);
        }
      });
  };
}

export function ResetPasswordProfile(values, token) {
  return async (dispatch) => {
    await axios
      .post(`${BASE_URL}/resetpasswordprofile`, { values, token })
      .then((resp) => {
        toastr.success("Ok!", "Senha Alterada com Sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error(
            "Ops!!",
            "Algo de errado ocorreu solicite alteração da senha novamente"
          );
        } else {
          e.response.data.forEach((mg) => toastr.error("Ops!!", mg.message));
        }
      });
  };
}

export function UploadImageLogo(file, token, id) {
  const headers = authorization(token);
  return async (dispacth) => {
    await axios.post(`${BASE_URL}/imagelogoempresa/${id}`, file, {
      headers: headers,
    });
  };
}

export function likeDocUser(idVaga, like, token, idUser) {
  const headers = authorization(token);
  return async (dispatch) => {
    await axios
      .put(`${BASE_URL}/likedocuser/${idVaga}/${like}/${idUser}`, {
        headers: headers,
      })
      .then((resp) => {
        resp.data.forEach((mg) => toastr.success("Ok!", mg.message));
      })
      .catch((e) => {
        e.response.data.forEach((mg) => toastr.error("Ops!!", mg.message));
      });
  };
}
*/
