/* eslint-disable */
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/auth";
import moment from "moment";
moment().format();
import NavCandidato from "../../Components/Header/NavCandidato";
import DialogComponent from "../../Components/Dialog/Dialog";
import { validateToken } from "../../actions/userActions";
import LoadingCompoent from "../../Components/Loading/Loading";
import { getCursos, getExp, getUser } from "../../actions/userActions";
import Estado from "../../Service/Estados";
import Cidade from "../../Service/Cidade";
import EditarInfoPessoais from "./Editar/EditarInfoPessoais";
import AdicionarExperiencia from "./Adicionar/AdicionarExperiencia";
import EditarExperiencia from "./Editar/EditarExperiencia";
import AdicionarEducacao from "./Adicionar/AdicionarEducacao";
import EditarEducacao from "./Editar/EditarEducacao";
import AdicionarHabilidade from "./Adicionar/AdicionarHabilidade";
import DeletarExperiencia from "./Deletar/DeleteExperiencia";
import DeletarEducacao from "./Deletar/DeleteEducacao";
import EditarHabilidade from "./Editar/EditarHabilidade";
import AlertComponent from "../../Components/Alert/Alert";
import authSearchUser from "../../Service/authSearchUser";

export default function Curriculo() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExp, setIsOpenExp] = useState(false);
  const [isOpenEditExp, setIsOpenEditExp] = useState(false);
  const [isOpenEducacao, setIsOpenEducacao] = useState(false);
  const [isOpenHabilidade, setIsOpenHabilidade] = useState(false);
  const [isOpenEditEducacao, setIsOpenEditEducacao] = useState(false);
  const [user, setUser] = useState("");
  const [curso, setCurso] = useState();
  const [cursoEdit, setCursoEdit] = useState();
  const [experiencia, setExperiencia] = useState();
  const [experienciaEdit, setExperienciaEdit] = useState();
  const [objetivoEdit, setObjetivoEdit] = useState(false);

  const [experienciaDelete, setexperienciaDelete] = useState(false);
  const [educacaoDelete, setEducacaoDelete] = useState(false);
  const [expDeleteID, setExpDeleteID] = useState();
  const [educaocaDeleteID, seteducaocaDeleteID] = useState();
  const [token, setToken] = useState();
  const [RecallComponent, setReacall] = useState(Math.random());

  const [loading, setLoading] = useState(false);

  const { handleLogout } = useContext(Context);
  const navigate = useNavigate();

  const escolaridade = [
    { id: "FI", nameescolaridade: "Ensino Fundamental - Incompleto" },
    { id: "FC", nameescolaridade: "Ensino Fundamental - Completo" },
    { id: "MI", nameescolaridade: "Ensino Medio - Incompleto" },
    { id: "MC", nameescolaridade: "Ensino Medio - Completo" },
    { id: "SI", nameescolaridade: "Ensino Superior - Incompleto" },
    { id: "SC", nameescolaridade: "Ensino Superior - Completo" },
  ];

  const sexo = [
    {
      id: "M",
      namesexo: "Masculino",
      unavailable: false,
    },

    { id: "F", namesexo: "Feminino", unavailable: false },
    { id: "N", namesexo: "Não informar", unavailable: false },
  ];

  useEffect(() => {
    async function userDate() {
      setLoading(true);
      const token = await authSearchUser(handleLogout, navigate, validateToken);
      if (token === undefined || !token) {
        return;
      }
      setToken(token);
      const getExpUser = await getExp(token);
      const getUserDate = await getUser(token);
      const getCursosUser = await getCursos(token);

      await setExperiencia(getExpUser);
      await setCurso(getCursosUser);
      await setUser(getUserDate);
      setLoading(false);
    }
    userDate();
  }, []);

  useEffect(() => {
    async function userDate() {
      const token = await authSearchUser(handleLogout, navigate, validateToken);
      if (token === undefined || !token) {
        return;
      }
      setToken(token);

      const getExpUser = await getExp(token);
      const getUserDate = await getUser(token);
      const getCursosUser = await getCursos(token);

      await setExperiencia(getExpUser);
      await setCurso(getCursosUser);
      await setUser(getUserDate);
    }
    userDate();
  }, [RecallComponent]);

  async function expDate() {
    const isLocalStorage = JSON.parse(localStorage.getItem("_myuser_"));
    const token = isLocalStorage.token;

    setToken(token);

    const getExpUser = await getExp(token);
    await setExperiencia(getExpUser);
  }

  async function closeModal() {
    setReacall(Math.random());
    setIsOpen(false);
  }

  function openInformacoes() {
    setIsOpen(true);
  }

  function openAddExperiencia() {
    setIsOpenExp(true);
  }

  function atualExp(value) {
    if (value === "true") {
      return (
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Momento Atual
        </span>
      );
    } else {
      return "";
    }
  }

  function atualCurso(value) {
    if (value === "true") {
      return (
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Realizando Atualmente
        </span>
      );
    } else {
      return "";
    }
  }

  function dateExperiencia(dataadmissao, datatermino, atual) {
    var admissao = moment(dataadmissao);
    var termino = atual === "true" ? moment(new Date()) : moment(datatermino);

    var anos = termino.diff(admissao, "year");
    var meses = termino.diff(admissao, "month");
    var dias = termino.diff(admissao, "day");

    if (anos <= 0 && meses > 0) {
      return meses + " Mes";
    } else if (meses <= 0) {
      if (dias <= 0) {
        return "1 Dia";
      }
      return dias + " Dias";
    } else {
      return anos + " Anos";
    }
  }

  function openEditExperiencia(id) {
    const result = experiencia[0].filter((exp) => exp._id === id);
    setExperienciaEdit(result);
    setIsOpenEditExp(true);
  }

  function closeModalExperiencia() {
    setIsOpenExp(false);
    expDate();
  }
  function closeModalEditExperiencia() {
    setIsOpenEditExp(false);
    setReacall(Math.random());
  }

  function openAddEducacao() {
    setIsOpenEducacao(true);
  }

  function openEditEducacao(id) {
    const result = curso[0].filter((cur) => cur._id === id);
    setCursoEdit(result);
    setIsOpenEditEducacao(true);
  }

  function closeModalEditEducacao() {
    setIsOpenEditEducacao(false);
    setReacall(Math.random());
  }

  function closeModalEducacao() {
    setReacall(Math.random());
    setIsOpenEducacao(false);
  }

  function openAddHabilidades() {
    setIsOpenHabilidade(true);
  }
  function closeModalHabilidade() {
    setIsOpenHabilidade(false);
  }

  function openDeleteExp(date) {
    setExpDeleteID(date);

    setexperienciaDelete(true);
  }

  function openDeleteEducacao(date) {
    seteducaocaDeleteID(date);
    setEducacaoDelete(true);
  }

  function CloseDeleteExp() {
    setReacall(Math.random());
    setexperienciaDelete(false);
  }

  function CloseDeleteEducacao() {
    setReacall(Math.random());
    setEducacaoDelete(false);
  }

  function openEditObjetivo() {
    setObjetivoEdit(true);
  }

  function CloseEditObjetivo() {
    setReacall(Math.random());
    setObjetivoEdit(false);
  }

  function dateFormat(date) {
    const newDate = new Date(date);
    const dateformat = newDate.toLocaleDateString("pt-br");
    return dateformat;
  }

  return (
    <>
      <DialogComponent
        openDialog={isOpen}
        closeModal={closeModal}
        title={"Editar Informações Pessoais"}
        component={
          <EditarInfoPessoais
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            userDate={user}
          />
        }
      />
      <DialogComponent
        openDialog={isOpenExp}
        closeModal={closeModalExperiencia}
        title={"Adicionando Experiencia"}
        component={
          <AdicionarExperiencia
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
          />
        }
      />
      <DialogComponent
        openDialog={isOpenEditExp}
        closeModal={closeModalEditExperiencia}
        title={"Editando Experiencia"}
        component={
          <EditarExperiencia
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            expDate={experienciaEdit}
          />
        }
      />

      <DialogComponent
        openDialog={isOpenEducacao}
        closeModal={closeModalEducacao}
        title={"Adicionando Cursos e Certificações"}
        component={
          <AdicionarEducacao
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
          />
        }
      />
      <DialogComponent
        openDialog={isOpenEditEducacao}
        closeModal={closeModalEditEducacao}
        title={"Editando Cursos e Certificações"}
        component={
          <EditarEducacao
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            curDate={cursoEdit}
          />
        }
      />

      <DialogComponent
        openDialog={isOpenHabilidade}
        closeModal={closeModalHabilidade}
        title={"Adicionando Habilidades,Competencias"}
        component={
          <AdicionarHabilidade
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
          />
        }
      />

      <DialogComponent
        openDialog={experienciaDelete}
        closeModal={CloseDeleteExp}
        title={"Deletando Experiencia"}
        component={
          <DeletarExperiencia
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            close={CloseDeleteExp}
            dateDelete={expDeleteID}
          />
        }
      />

      <DialogComponent
        openDialog={educacaoDelete}
        closeModal={CloseDeleteEducacao}
        title={"Deletando Educação e Certificação"}
        component={
          <DeletarEducacao
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            close={CloseDeleteEducacao}
            dateDelete={educaocaDeleteID}
          />
        }
      />

      <DialogComponent
        openDialog={objetivoEdit}
        closeModal={CloseEditObjetivo}
        title={
          "Editando suas habilidade, competencias e objetivos profissionais"
        }
        component={
          <EditarHabilidade
            handleLogout={handleLogout}
            navigate={navigate}
            valid={validateToken}
            dateObjetivo={user}
          />
        }
      />

      <div className="h-screen flex flex-col">
        <div className="flex">
          <NavCandidato />

          <main className="flex-1 bg-gray-400/10 sm:p-16 p-6 lg:block">
            <AlertComponent
              message={
                "Caso precise de ajuda e/ou suporte, entre em contato conosco atraves das redes sociais, como Facebook, Instagram."
              }
            />
            <div className="grid grid-cols-1 gap-4 mt-10">
              <div className="bg-white">
                <div className="p-3">
                  <div className="flex flex-col sm:flex-row">
                    <img
                      src="https://i.ibb.co/LP2cKrj/user.png"
                      className="w-12 h-12"
                    />
                    <p className="p-3 text-xl font-semibold">
                      Informações pessoais
                      <small className="flex text-slate-600 text-sm">
                        Aqui fica algumas informações pessoais para que o
                        recrutador avalie seu curriculo.
                      </small>
                    </p>
                    <div className="flex flex-1 justify-end">
                      <div className="justify-end content-end">
                        <button
                          onClick={openInformacoes}
                          type="button"
                          className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                        >
                          Editar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6">
                  {loading ? (
                    <LoadingCompoent />
                  ) : (
                    <>
                      <div>
                        <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                          Nome
                        </label>
                        <p className="text-sm font-semibold">{user.nome}</p>
                      </div>
                      <div>
                        <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                          Endereço de email
                        </label>
                        <p className="text-sm font-semibold">{user.email}</p>
                      </div>
                      <div>
                        <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                          Sexo
                        </label>
                        <p className="text-sm font-semibold">
                          {sexo.map((sexoValue) => {
                            if (sexoValue.id === user.sexo) {
                              return sexoValue.namesexo;
                            } else {
                              return "";
                            }
                          })}
                        </p>
                      </div>
                      <div>
                        <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                          Escolaridade
                        </label>
                        <p className="text-sm font-semibold">
                          {escolaridade.map((escolaridadeValue) => {
                            if (escolaridadeValue.id === user.escolaridade) {
                              return escolaridadeValue.nameescolaridade;
                            } else {
                              return "";
                            }
                          })}
                        </p>
                      </div>
                      <div>
                        <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                          Data Nascimento
                        </label>
                        <p className="text-sm font-semibold">
                          {dateFormat(user.datanascimento)}
                        </p>
                      </div>
                      <div>
                        <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                          Telefone
                        </label>
                        <p className="text-sm font-semibold">
                          {user.telefone}{" "}
                        </p>
                      </div>
                      <div>
                        <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                          Cidade - Estado
                        </label>
                        <p className="text-sm font-semibold">
                          {Cidade.map((cidadeValue) => {
                            if (cidadeValue.ID == user.cidade) {
                              return cidadeValue.Nome;
                            } else {
                              return "";
                            }
                          })}
                          -
                          {Estado.map((estadoValue) => {
                            if (estadoValue.ID == user.estado) {
                              return estadoValue.Sigla;
                            } else {
                              return "";
                            }
                          })}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="bg-white">
                <div className="flex flex-col p-3 sm:flex-row">
                  <div className="pt-3">
                    <img
                      src="https://i.ibb.co/mvtmbhP/experience.png"
                      className="w-12 h-12"
                    />
                  </div>
                  <p className="p-3 flex-grow text-xl font-semibold">
                    Experiência
                    <small className="flex text-slate-600 text-sm">
                      Adicione suas experiencias profissionais.
                    </small>
                  </p>

                  <div className="flex flex-1 justify-end">
                    <div className="justify-end content-end">
                      <button
                        onClick={openAddExperiencia}
                        type="button"
                        className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                      >
                        Adicionar experiência
                      </button>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 p-6">
                  {loading ? (
                    <LoadingCompoent />
                  ) : experiencia === undefined ||
                    experiencia === null ||
                    experiencia[0].length === 0 ? (
                    <p>Adicione aqui suas experiencias profissionais</p>
                  ) : (
                    experiencia[0].map((exp) => (
                      <div className="flex  flex-col sm:flex-row  gap-4 sm:gap-8">
                        <div className="flex flex-col">
                          <p className="text-md font-semibold">
                            {exp.funcaoexercida} {atualExp(exp.atual)}
                          </p>
                          <small className="text-sm">
                            {exp.nomeempresa} -{" "}
                            {dateExperiencia(
                              exp.dataadmissao,
                              exp.datatermino,
                              exp.atual
                            )}
                          </small>
                          <small className="text-sm"></small>
                        </div>
                        <div className="flex sm:flex-1  gap-1 sm:gap-4 justify-end">
                          <button
                            className="py-2.5 px-5 h-9 text-center font-semibold block bg-red-600 rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-red-600 lg:mx-0"
                            onClick={() => openDeleteExp(exp)}
                          >
                            Excluir
                          </button>
                          <button
                            className="py-2.5 px-5 h-9 text-center font-semibold block bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                            onClick={() => openEditExperiencia(exp._id)}
                          >
                            Editar
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div className="bg-white">
                <div className="flex flex-col  sm:flex-row p-3">
                  <img
                    src="https://i.ibb.co/TwpKw14/education.png"
                    className="w-12 h-12"
                  />
                  <p className="flex-grow p-3 text-xl font-semibold">
                    Certificações e/ou cursos
                    <small className="flex text-slate-600 text-sm">
                      Adicione cursos profisionais e certificações.
                    </small>
                  </p>
                  <div className="flex flex-1 justify-end">
                    <div className="justify-end content-end">
                      <button
                        onClick={openAddEducacao}
                        type="button"
                        className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                      >
                        Adicionar cerificação/cursos
                      </button>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 p-6">
                  {loading ? (
                    <LoadingCompoent />
                  ) : curso === undefined ||
                    curso === null ||
                    curso[0].length === 0 ? (
                    <p>Adicione seus cursos e certificações</p>
                  ) : (
                    curso[0].map((cur) => (
                      <div className="flex flex-col sm:flex-row gap-8">
                        <div className="flex flex-col">
                          <p className="text-md font-semibold">
                            {cur.nomecurso} {atualCurso(cur.atual)}
                          </p>
                          <small className="text-sm">
                            {cur.localcurso} -{" "}
                            {dateExperiencia(
                              cur.datainiciocurso,
                              cur.datafimcurso,
                              cur.atual
                            )}
                          </small>
                        </div>
                        <div className="flex flex-1 gap-4 justify-end">
                          <button
                            className="py-2.5 px-5 h-9 text-center font-semibold block bg-red-600 rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-red-600 lg:mx-0"
                            onClick={() => openDeleteEducacao(cur)}
                          >
                            Excluir
                          </button>
                          <button
                            className="py-2.5 px-5 h-9 text-center font-semibold block bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                            onClick={() => openEditEducacao(cur._id)}
                          >
                            Editar
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div className="bg-white mb-10">
                <div className="flex flex-col sm:flex-row p-3">
                  <img
                    src="https://i.ibb.co/c1jTrtY/skills.png"
                    className="w-12 h-12"
                  />
                  <p className="p-3 text-xl font-semibold">
                    {" "}
                    Objetivos
                    <small className="flex text-slate-600 text-sm">
                      Adicione seus objetivos profissionais.
                    </small>
                  </p>
                  {user.objetivo ? (
                    <div className="flex flex-1 justify-end">
                      <div className="justify-end content-end">
                        <button
                          onClick={openEditObjetivo}
                          type="button"
                          className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                        >
                          Editar
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-1 justify-end">
                      <div className="justify-end content-end">
                        <button
                          onClick={openEditObjetivo}
                          type="button"
                          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs p-3 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                        >
                          Adicionar objetivo
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {loading ? (
                  <LoadingCompoent />
                ) : user.objetivo ? (
                  <div className="gap-4 p-6">{user.objetivo}</div>
                ) : (
                  <div className="gap-4 p-6">
                    Descreva aqui seus objetivos,compentecias e habilidades
                    profissionais.
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
