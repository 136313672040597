import axios from "axios";
import authorization from "../auth/authRouter";

const BASE_URL = process.env.REACT_APP_URL_USUARIO;
const BASE_URL_CURRICULO = process.env.REACT_APP_URL_CURRICULO;

//Actions GET
/*export async function getCandidateFeed(token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL}/candidatevaga`, {
    headers: headers,
  });
  return {
    type: "RETURN_CANDIDATE_TRUE_FALSE",
    payload: request,
  };
}

export async function getVagasCandidato(token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL}/soucandidato`, {
    headers: headers,
  });
  return {
    type: "RETURN_CANDIDATE_VAGA",
    payload: request,
  };
}

export async function downloadcurriculo(token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL}/downloadcurriculo`, {
    responseType: "arraybuffer",
    headers: headers,
  });
  return {
    type: "DOWNLOAD_CURRICULO_USER",
    payload: request,
  };
}

export async function getCurriculoCandidate(token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL}/meucurriculo`, {
    headers: headers,
  });
  return {
    type: "RETURN_CURRICULO",
    payload: request,
  };
}

export async function getCurriculoUser(token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL}/curriculocandidate`, {
    headers: headers,
  });
  return {
    type: "RETURN_THEN_CURRICULO",
    payload: request,
  };
}*/

export async function getCandidateFeed(token) {
  const headers = authorization(token);
  const response = await axios.get(`${BASE_URL}/candidatevaga`, {
    headers: headers,
  });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function getCursos(values) {
  const headers = authorization(values);
  const response = await axios.get(`${BASE_URL_CURRICULO}/cursousuario`, {
    headers: headers,
  });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

//Actions GET
export async function getUser(values) {
  const headers = authorization(values);
  const response = await axios.get(`${BASE_URL}/user`, { headers: headers });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function getExp(values) {
  const headers = authorization(values);
  const response = await axios.get(`${BASE_URL_CURRICULO}/expusuario`, {
    headers: headers,
  });

  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

//Actions POST

export async function UploadImgPefil(file, token, id) {
  const headers = authorization(token);

  const response = await axios.post(
    `${BASE_URL}/imageperfilusuario/${id}`,
    file,
    {
      headers: headers,
    }
  );

  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function createUser(values) {
  await axios
    .post(`${BASE_URL}/cadastro`, values)
    .then((resp) => {
      return resp.data.forEach((mg) => mg.message);
    })
    .catch((e) => {
      if (!e.response) {
        return "error";
      } else {
        return e.response.data.forEach((mg) => mg.message);
      }
    });
}

export async function postExp(value, token) {
  var valueSubmit = {
    nomeempresa: value.nomeempresa || "null",
    funcaoexercida: value.funcaoexercida || "null",
    dataadmissao: value.dataadmissao || "null",
    datatermino: value.datatermino || "null",
    atual: value.atual || "false",
  };

  const headers = authorization(token);

  const response = await axios
    .post(`${BASE_URL_CURRICULO}/expusuario`, valueSubmit, {
      headers: headers,
    })
    .then((resp) => {
      return resp.data.message;
    })
    .catch((e) => {
      return e;
    });

  if (!response) {
    return "Erro ao carregar suas experiencias.";
  } else {
    return response;
  }
}

export async function putExp(value, token, id) {
  const headers = authorization(token);
  const response = await axios
    .put(`${BASE_URL_CURRICULO}/expusuario/${id}`, value, {
      headers: headers,
    })
    .then((resp) => {
      return resp.data[0].message;
    })
    .catch((e) => {
      return e;
    });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response;
  }
}

export async function deleteExp(id, token) {
  const headers = authorization(token);
  const response = await axios
    .delete(`${BASE_URL_CURRICULO}/expusuario/${id}`, { headers: headers })
    .then((resp) => {
      return;
    })
    .catch((e) => {
      if (!e.response) {
        return e;
      } else {
        return e;
      }
    });

  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function postCurso(value, token) {
  const headers = authorization(token);
  const response = await axios
    .post(`${BASE_URL_CURRICULO}/cursousuario`, value, { headers: headers })
    .then((resp) => {
      if (!resp.data.success) {
        return resp;
      } else {
        return resp.data.message;
      }
    })
    .catch((e) => {
      return e;
    });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function putCurso(value, token, id) {
  const headers = authorization(token);
  const response = await axios
    .put(`${BASE_URL_CURRICULO}/cursousuario/${id}`, value, {
      headers: headers,
    })
    .then((resp) => {
      return resp.data[0].message;
    })
    .catch((e) => {
      return e;
    });

  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response;
  }
}

export async function deleteCurso(id, token) {
  const headers = authorization(token);
  await axios
    .delete(`${BASE_URL_CURRICULO}/cursousuario/${id}`, { headers: headers })
    .then((resp) => {
      if (!resp.data.success) {
        return;
      } else {
        return resp.data.message;
      }
    })
    .catch((e) => {
      if (!e.response) {
        return "Erro nos nosso serviço";
      } else {
        return e;
      }
    });
}

export async function Objetivo_Competencia(token, value) {
  const headers = authorization(token);
  const response = await axios
    .post(`${BASE_URL}/adicionarobjecomp`, value, { headers: headers })
    .then((resp) => {
      return resp.data[0].message;
    })
    .catch((e) => {
      if (!e.response) {
        return;
      } else {
        return e;
      }
    });

  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response;
  }
}

// Put

export async function updateUser(value, token) {
  const headers = authorization(token);
  const response = await axios
    .put(`${BASE_URL}/alterar`, value, { headers: headers })
    .then((resp) => {
      return resp; //resp.data[0].message;
    })
    .catch((e) => {
      if (!e.response) {
        return e;
      } else {
        return e;
      }
    });

  if (!response) {
    return false;
  } else {
    return response;
  }
}

export async function getCurriculoCandidate(token) {
  const headers = authorization(token);
  await axios.get(`${BASE_URL}/meucurriculo`, {
    headers: headers,
  });
}

export async function getCurriculoUser(token) {
  const headers = authorization(token);
  await axios.get(`${BASE_URL}/curriculocandidate`, {
    headers: headers,
  });
}

export async function postCandidate(idVaga, token) {
  const headers = authorization(token);
  const response = await axios
    .post(`${BASE_URL}/candidato/${idVaga}`, { headers: headers })
    .then((resp) => {
      return resp.data;
    })
    .catch((e) => {
      if (!e.response) {
        return "erro servidor";
      } else {
        return e;
      }
    });
  if (!response) {
    return { error: "Erro ao carregar seus dados" };
  } else {
    return response.data;
  }
}

export async function ResetPasswordProfile(values, idToken, token) {
  const headers = authorization(token);
  const response = await axios
    .post(
      `${BASE_URL}/resetpasswordprofile`,
      { values, idToken },
      { headers: headers }
    )
    .then((resp) => {
      return resp.data;
    })
    .catch((e) => {
      if (!e.response) {
        return "Ops! Estamos com problemas em nosso serviço tente mais tarde";
      } else {
        return e.response.data[0];
      }
    });

  if (!response) {
    return "Ops! Estamos com problemas em nosso serviço tente mais tarde";
  } else {
    return response.message;
  }
}

export async function validateToken(token) {
  const response = await axios
    .post(`${BASE_URL}/validtokenuser`, { token })
    .then((resp) => {
      return resp.data.valid;
    })
    .catch((e) => {
      if (!e.response) {
        return false;
      } else {
        return true;
      }
    });

  if (!response) {
    return false;
  } else {
    return response;
  }
}

export default async function forgotPasswordUser(values) {
  const response = await axios
    .post(`${BASE_URL}/forgetpassaword`, values)
    .then((resp) => {
      return resp;
    })
    .catch((e) => {
      if (!e.response) {
        return e;
      } else {
        return e;
      }
    });
  if (!response) {
    return false;
  } else {
    return response;
  }
}

export async function ResetForgotPasswordUser(values, token) {
  const response = await axios
    .post(`${BASE_URL}/resetpassword`, { values, token })
    .then((resp) => {
      return resp;
    })
    .catch((e) => {
      if (!e.response) {
        return "error";
      } else {
        return e;
      }
    });
  if (!response) {
    return false;
  } else {
    return response;
  }
}

/*
export function postCurriculo(file, token) {
  const headers = authorization(token);
  return async (dispatch) => {
    await axios
      .post(`${BASE_URL}/updatecurriculo`, file, { headers: headers })
      .then((resp) => {
        toastr.success("Ok!", "Curriculo adicionado com sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error(
            "Ops!",
            "Falta enviar Arquivo ou deve ser colocado aquivo deve esta em formato .pdf"
          );
        }
        toastr.error(
          "Ops!",
          "Falta enviar Arquivo ou deve ser colocado aquivo deve esta em formato .pdf"
        );
      });
  };
}

export function postCandidate(idVaga, token) {
  const headers = authorization(token);
  return async (dispatch) => {
    await axios
      .post(`${BASE_URL}/candidato/${idVaga}`, { headers: headers })
      .then((resp) => {
        toastr.success("Ok!", "Candidatado com sucesso!");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Falha ao candidatar");
        } else {
          e.response.data.forEach((mg) => toastr.error("Falha !!", mg.message));
        }
      });
  };
}

//Actions PUT
export function updateUser(value, token) {
  return (dispatch) => {
    const headers = authorization(token);
    axios
      .put(`${BASE_URL}/alterar`, value, { headers: headers })
      .then((resp) => {
        toastr.success("Ok!", "Usuario Alterdo Com Sucesso !");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          e.response.data.forEach((mg) => toastr.error("Falha !!", mg.message));
          // toastr.error('Ops !! ', "Verifique se todos os campos estao preenchidos")
        }
      });
  };
}

//Actions DELETE
export function deleteCurriculo(token) {
  const headers = authorization(token);
  return async (dispatch) => {
    await axios
      .delete(`${BASE_URL}/deletecurriculo`, { headers: headers })
      .then((resp) => {
        toastr.success("Ok!", "Curriculo Excluido com Sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          e.response.data.forEach((mg) => toastr.error("Falha !!", mg.message));
        }
      });
  };
}

export default function forgotPasswordUser(values) {
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/forgetpassaword`, values)
      .then((resp) => {
        dispatch([resetForm("FormUser"), push("/message_forgot_password")]);
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops", "Estamos com problemas no servidor Aguarde !");
        } else {
          dispatch([resetForm("FormUser"), push("/message_forgot_password")]);
        }
      });
  };
}

export function ResetPasswordUser(values, token) {
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/resetpassword`, { values, token })
      .then((resp) => {
        dispatch([push("/success_forgot_password")]);
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error(
            "Erro",
            "Algo de errado ocorreu solicite alteração da senha novamente"
          );
          dispatch([push("/login")]);
        } else {
          dispatch([push("/")]);
        }
      });
  };
}

export function ResetPasswordProfile(values, token) {
  return async (dispatch) => {
    await axios
      .post(`${BASE_URL}/resetpasswordprofile`, { values, token })
      .then((resp) => {
        toastr.success("Ok!", "Senha Alterada com Sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error(
            "Ops!",
            "Algo de errado ocorreu solicite alteração da senha novamente"
          );
        } else {
          e.response.data.forEach((mg) => toastr.error("Ops!!", mg.message));
        }
      });
  };
}

export function UploadImgPefil(file, token, id) {
  const headers = authorization(token);
  return async (dispacth) => {
    await axios.post(`${BASE_URL}/imageperfilusuario/${id}`, file, {
      headers: headers,
    });
  };
}

export async function getCursos(token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL_CURRICULO}/cursousuario`, {
    headers: headers,
  });
  return {
    type: "RETURN_LIST_CURSO",
    payload: request,
  };
}

export async function getCursosID(token, id) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL_CURRICULO}/cursousuario/${id}`, {
    headers: headers,
  });
  return {
    type: "RETURN_LIST_CURSO_EDIT",
    payload: request,
  };
}

export function postCurso(value, token) {
  const headers = authorization(token);
  return async (dispacth) => {
    await axios
      .post(`${BASE_URL_CURRICULO}/cursousuario`, value, { headers: headers })
      .then((resp) => {
        toastr.success("Curso Cadastrado com sucesso");
        dispacth(resetForm("FormCurso"));
      })
      .catch((e) => {
        e.response.data.forEach((mg) => toastr.error("Ops!!", mg.message));
      });
  };
}

export function putCurso(value, token, id) {
  return async (dispatch) => {
    const headers = authorization(token);
    await axios
      .put(`${BASE_URL_CURRICULO}/cursousuario/${id}`, value, {
        headers: headers,
      })
      .then((resp) => {
        toastr.success("Ok!", "Curso Alterado com sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          e.response.data.forEach((mg) => toastr.error("Falha !!", mg.message));
        }
      });
  };
}

export function deleteCurso(id, token) {
  return async (dispacth) => {
    const headers = authorization(token);
    await axios
      .delete(`${BASE_URL_CURRICULO}/cursousuario/${id}`, { headers: headers })
      .then((resp) => {
        toastr.success("Ok!", "Curso deletado com sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          toastr.error("Error!", "Falha ao deletar curso");
        }
      });
  };
}

export function postExp(value, token) {
  var valueSubmit = {
    nomeempresa: value.nomeempresa || "null",
    funcaoexercida: value.funcaoexercida || "null",
    dataadmissao: value.dataadmissao || "null",
    datatermino: value.datatermino || "null",
  };

  const headers = authorization(token);
  return async (dispacth) => {
    await axios
      .post(`${BASE_URL_CURRICULO}/expusuario`, valueSubmit, {
        headers: headers,
      })
      .then((resp) => {
        if (!resp.data.success)
          resp.data.error.forEach((mg) => toastr.error("Ops!!", mg.message));
        else {
          toastr.success(resp.data.message);
          dispacth(resetForm("FormAddExpericia"));
        }
      })
      .catch((e) => {
        toastr.error("Ops!!", "Algo de errado aconteceu tente novamente");
      });
  };
}

export async function getExpID(token, id) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL_CURRICULO}/expusuario/${id}`, {
    headers: headers,
  });
  return {
    type: "RETURN_LIST_EXPERICIENCIA_EDIT",
    payload: request,
  };
}

export async function getExp(token) {
  const headers = authorization(token);
  const request = await axios.get(`${BASE_URL_CURRICULO}/expusuario`, {
    headers: headers,
  });

  return {
    type: "RETURN_LIST_EXP",
    payload: request,
  };
}

export function putExp(value, token, id) {
  return async (dispatch) => {
    const headers = authorization(token);
    await axios
      .put(`${BASE_URL_CURRICULO}/expusuario/${id}`, value, {
        headers: headers,
      })
      .then((resp) => {
        toastr.success("Ok!", "Experiencia  Alterado com sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          e.response.data.forEach((mg) => toastr.error("Falha !!", mg.message));
        }
      });
  };
}

export function deleteExp(id, token) {
  return async (dispacth) => {
    const headers = authorization(token);
    await axios
      .delete(`${BASE_URL_CURRICULO}/expusuario/${id}`, { headers: headers })
      .then((resp) => {
        toastr.success("Ok!", "Experiencia deletada com sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          toastr.error("Error!", "Falha ao deletar curso");
        }
      });
  };
}

export function Objetivo_Competencia(token, value) {
  return async (dispatch) => {
    const headers = authorization(token);
    await axios
      .post(`${BASE_URL}/adicionarobjecomp`, value, { headers: headers })
      .then((resp) => {
        toastr.success("Ok!", "Curriculo salvo com sucesso");
      })
      .catch((e) => {
        if (!e.response) {
          toastr.error("Ops!", "Estamos com problemas no servidor Aguarde !");
        } else {
          e.response.data.forEach((mg) => toastr.error("Falha !!", mg.message));
        }
      });
  };
}*/
