import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

//Routes Import
import RouteComponent from "./main/router";
import { AuthProvider } from "./context/auth";

function App(props) {
  return (
    <>
      <AuthProvider>
        <Router>
          <RouteComponent />
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
