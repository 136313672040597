import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../Pages/Home/Home";
import CadastroCandidato from "../Pages/Cadastro/Candidato";
import CadastroEmpresa from "../Pages/Cadastro/Empresa";
import Login from "../Pages/Login/Login";
import Curriculo from "../Pages/Candidato/Curriculo";
import EsqueceuSenha from "../Pages/EsqueceuSenha/EsqueceuSenhaCandidato";
import Vagas from "../Pages/Candidato/Vagas";
import { PrivateRouteUser } from "./privateRouterUser";
import Notificacao from "../Pages/Candidato/Notificacoes";
import Configuracao from "../Pages/Candidato/Configuracao";
import CadastroSucessoCandidato from "../Pages/Cadastro/CadastroSucessoCandidato";
import CadastroSucessoEmpresa from "../Pages/Cadastro/CadastroSucessoEmpresa";
import TermosContratos from "../Pages/Termos_e_sobre/Termo";
import Sobre from "../Pages/Termos_e_sobre/Sobre";
import NotificacaoEmpresa from "../Pages/Empresa/Notificacoes";
import ConfiguracoesComp from "../Pages/Empresa/Configuracao";
import CurriculoForEmpresa from "../Pages/Empresa/Candidatos/pageCandidate";
import EsqueceuSenhaSucesso from "../Pages/EsqueceuSenha/EsqueceuSenha";
import ResetPasswordUser from "../Pages/EsqueceuSenha/ResetSenhaUser";
import ResetPasswordComp from "../Pages/EsqueceuSenha/ResetSenhaComp";
import SenhaAlteradaSucesso from "../Pages/EsqueceuSenha/SenhaAlteradaSucesso";
import LoginAdmin from "../Pages/Admin/LoginAdmin";
import DashboardAdmin from "../Pages/Admin/DashboardAdmin";
import Dashboard2 from "../Pages/Empresa/Dashboard2";
import EditarInfoEmpresa from "../Pages/Empresa/Editar/EditarInfoComp";

const RouteComponent = (props) => (
  <Routes>
    <Route path="/" Component={Home} />
    <Route path="/cadastrocandidato" element={<CadastroCandidato />} />
    <Route path="/cadastroempresa" element={<CadastroEmpresa />} />
    <Route path="/login" element={<Login />} />
    <Route path="/termosecondicoes" element={<TermosContratos />} />
    <Route path="/sobrenos" element={<Sobre />} />
    <Route
      path="/curriculocandidato/:idUser/:idVaga/:nome"
      element={<CurriculoForEmpresa />}
    />

    <Route path="/9994744b25e0900804d7627e13711d75" element={<LoginAdmin />} />
    <Route path="/admin" element={<DashboardAdmin />} />
    <Route
      path="/cadastrosucessocandidato"
      element={<CadastroSucessoCandidato />}
    />
    <Route
      path="/cadastrosucessoempresa"
      element={<CadastroSucessoEmpresa />}
    />
    <Route path="/esqueceusenha" element={<EsqueceuSenha />} />
    <Route path="/recuperacaosenha" element={<EsqueceuSenhaSucesso />} />
    <Route path="/sucessosenha" element={<SenhaAlteradaSucesso />} />
    <Route
      path="/60916fa8054d3d11ff117537c702cf10/:token"
      element={<ResetPasswordUser />}
    />
    <Route
      path="/df963bffff1fe71c8273c3f22cd7e071/:token"
      element={<ResetPasswordComp />}
    />

    <Route element={<PrivateRouteUser />}>
      <Route element={<Curriculo />} path="/curriculo" />
      <Route element={<Vagas />} path="/vagas" />
      <Route element={<Notificacao />} path="/notificacaousuario" />
      <Route element={<Configuracao />} path="/configuracaousuario" />

      <Route element={<Dashboard2 />} path="/dashboard" />
      <Route element={<EditarInfoEmpresa />} path="/dadosempresa" />
      <Route element={<NotificacaoEmpresa />} path="/notificacaoempresa" />
      <Route element={<ConfiguracoesComp />} path="/configuracaoempresa" />
    </Route>
  </Routes>
);

export default RouteComponent;
