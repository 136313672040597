/* eslint-disable */
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UsersCandidateVaga } from "../../../actions/vagasAction";
import LoadingCompoent from "../../../Components/Loading/Loading";
import {
  ArrowLeft,
  ArrowRight,
  Calendar,
  Mail,
  PhoneCall,
  User,
} from "lucide-react";
import authSearchComp from "../../../Service/authSearchComp";

export default function CandidatosVagas(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [vaga, setVaga] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAddVaga, setIsOpenAddVaga] = useState(false);
  const [isOpenEditarVaga, setIsOpenEditarVaga] = useState(false);
  const [dateVaga, setDateVaga] = useState("");
  const [listVagaCandidate, setCandaditeVaga] = useState([]);
  const [pages, setPages] = useState(1);
  const [RecallComponent, setReacall] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const [listFilter, setListFilter] = useState([]);
  const [token, setToken] = useState();
  const [comp, setComp] = useState("");
  const [candidato, setCandidato] = useState([]);
  const navigate = useNavigate();

  function openInformacoes() {
    setIsOpen(true);
  }

  function openAdicionarVagas() {
    setIsOpenAddVaga(true);
  }

  async function closeModal() {
    setReacall(Math.random());
    setIsOpen(false);
  }

  async function closeModalAddVaga() {
    setReacall(Math.random());
    setIsOpenAddVaga(false);
  }

  async function closeModalEditarVaga() {
    setReacall(Math.random());
    setIsOpenEditarVaga(false);
  }

  useEffect(() => {
    async function candidateVaga() {
      setLoading(true);
      const token = await authSearchComp(
        props.handleLogout,
        props.navigate,
        props.valid
      );
      if (token === undefined || !token) {
        return;
      }

      const getCandidateVaga = await UsersCandidateVaga(
        props.vagaDate[0]._id,
        token
      );

      await setCandidato(getCandidateVaga);
      setLoading(false);
    }
    candidateVaga();
  }, []);

  console.log("Candidato", candidato);
  return (
    <>
      <div className="gap-4 pb-4">
        <p className="text-lg font-light">
          Esse sao os candidatos a essa vaga: {props.vagaDate[0].titulovaga}
        </p>
      </div>
      <div className="grid grid-cols-4 gap-2">
        {loading ? (
          <LoadingCompoent />
        ) : candidato.length === 0 ? (
          <p>Voce ainda não possui candidatos para essa vaga.</p>
        ) : (
          candidato.map((c) => (
            <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6 dark:bg-gray-800 dark:border-gray-700">
              <div className="">
                <div className="">
                  <div className="gap-4">
                    <div className="flex text-center gap-2 items-center">
                      <User />
                      <p className="text-md font-light flex">Nome</p>
                    </div>
                    <small className="text-sm font-">{c.nome}</small>
                  </div>

                  <div className="pt-4">
                    <div className="flex text-center gap-2 items-center">
                      <Calendar />
                      <p className="text-md font-light flex">Data Nascimento</p>
                    </div>
                    <small className="text-sm">{c.datanascimento}</small>
                  </div>

                  <div className="pt-4">
                    <div className="flex text-center gap-2 items-center">
                      <PhoneCall />
                      <p className="text-md font-light flex">Telefone</p>
                    </div>
                    <small className="text-sm">{c.telefone}</small>
                  </div>

                  <div className="pt-4">
                    <div className="flex text-center gap-2 items-center">
                      <Mail />
                      <p className="text-md font-light flex">Email</p>
                    </div>
                    <small className="text-sm">{c.email}</small>
                  </div>
                </div>

                <div className="pt-4">
                  <a
                    href={
                      "/curriculocandidato/" +
                      c.idUser +
                      "/" +
                      props.vagaDate[0]._id +
                      "/" +
                      c.nome
                    }
                    target="_blank"
                    className="py-2.5 px-5 h-9 text-center font-semibold block w-full bg-blue-me rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-blue-me lg:mx-0"
                  >
                    ver curriculo completo
                  </a>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
}

//joseteste@gmail.com
