/* eslint-disable */
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
//import { Context } from "../../context/auth";
import moment from "moment";
moment().format();

import Estado from "../../../Service/Estados";
import Cidade from "../../../Service/Cidade";
import { UsersCandidateVaga } from "../../../actions/vagasAction";
import { ArrowUpAZ } from "lucide-react";
import authSearchComp from "../../../Service/authSearchComp";

export default function CurriculoForEmpresa(props) {
  const [user, setUser] = useState("");
  const [curso, setCurso] = useState();
  const [experiencia, setExperiencia] = useState();
  const [obj, setObj] = useState("");
  const [candidato, setCandidato] = useState("");
  const [token, setToken] = useState("");

  const hiddenFileInput = useRef(null);

  //const { handleLogout } = useContext(Context);
  const navigate = useNavigate();

  const escolaridade = [
    { id: "FI", nameescolaridade: "Ensino Fundamental - Incompleto" },
    { id: "FC", nameescolaridade: "Ensino Fundamental - Completo" },
    { id: "MI", nameescolaridade: "Ensino Medio - Incompleto" },
    { id: "MC", nameescolaridade: "Ensino Medio - Completo" },
    { id: "SI", nameescolaridade: "Ensino Superior - Incompleto" },
    { id: "SC", nameescolaridade: "Ensino Superior - Completo" },
  ];

  const sexo = [
    {
      id: "M",
      namesexo: "Masculino",
      unavailable: false,
    },

    { id: "F", namesexo: "Feminino", unavailable: false },
    { id: "N", namesexo: "Não informar", unavailable: false },
  ];

  const parametro = useParams();

  document.title = parametro.nome;

  useEffect(() => {
    async function candidateVaga() {
      const token = await authSearchComp(
        props.handleLogout,
        props.navigate,
        props.validateToken
      );
      if (token === undefined || !token) {
        return;
      }

      const getCandidateVaga = await UsersCandidateVaga(
        parametro.idVaga,
        token
      );

      await setCandidato(getCandidateVaga);
    }
    candidateVaga();
  }, []);

  useEffect(() => {
    async function userCandidate() {
      candidato.length <= 0
        ? ""
        : candidato.map((c) => {
            if (c.idUser === parametro.idUser) {
              console.log("Date objetivo user > ", c);
              setUser(c);
              setCurso(c.listCurso);
              setExperiencia(c.listExperiencia);
              setObj(c.objetivo);
            }
          });
    }
    userCandidate();
  }, [candidato]);

  function atualCurso(value) {
    if (value === "true") {
      return (
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Realizando Atualmente
        </span>
      );
    } else {
      return "";
    }
  }

  function atualExp(value) {
    if (value === "true") {
      return (
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Momento Atual
        </span>
      );
    } else {
      return "";
    }
  }

  function dateExperiencia(dataadmissao, datatermino, atual) {
    var admissao = moment(dataadmissao);
    var termino = atual === "true" ? moment(new Date()) : moment(datatermino);

    var anos = termino.diff(admissao, "year");
    var meses = termino.diff(admissao, "month");
    var dias = termino.diff(admissao, "day");

    if (anos <= 0 && meses > 0) {
      return meses + " Mes";
    } else if (meses <= 0) {
      if (dias <= 0) {
        return "1 Dia";
      }
      return dias + " Dias";
    } else {
      return anos + " Anos";
    }
  }

  return (
    <>
      <div className="h-screen flex flex-col">
        <div className="flex">
          <main className="flex-1 bg-gray-400/10 sm:p-16 p-6 lg:block">
            <div className="grid grid-cols-1 gap-4 mt-10">
              <div className="bg-white">
                <div className="p-3">
                  <div className="flex flex-row">
                    <img
                      src="https://i.ibb.co/LP2cKrj/user.png"
                      className="w-12 h-12"
                    />
                    <p className="p-3 text-xl font-semibold">
                      Informações pessoais
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6">
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Nome
                    </label>
                    <p className="text-sm font-semibold">{user.nome}</p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Endereço de email
                    </label>
                    <p className="text-sm font-semibold">{user.email}</p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Sexo
                    </label>
                    <p className="text-sm font-semibold">
                      {sexo.map((sexoValue) => {
                        if (sexoValue.id === user.sexo) {
                          return sexoValue.namesexo;
                        } else {
                          return "";
                        }
                      })}
                    </p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Escolaridade
                    </label>
                    <p className="text-sm font-semibold">
                      {escolaridade.map((escolaridadeValue) => {
                        if (escolaridadeValue.id === user.escolaridade) {
                          return escolaridadeValue.nameescolaridade;
                        } else {
                          return "";
                        }
                      })}
                    </p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Data Nascimento
                    </label>
                    <p className="text-sm font-semibold">
                      {user.datanascimento}
                    </p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Telefone
                    </label>
                    <p className="text-sm font-semibold">{user.telefone} </p>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900/70">
                      Cidade - Estado
                    </label>
                    <p className="text-sm font-semibold">
                      {Cidade.map((cidadeValue) => {
                        if (cidadeValue.ID == user.cidade) {
                          return cidadeValue.Nome;
                        } else {
                          return "";
                        }
                      })}
                      -
                      {Estado.map((estadoValue) => {
                        if (estadoValue.ID == user.estado) {
                          return estadoValue.Sigla;
                        } else {
                          return "";
                        }
                      })}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white">
                <div className="flex flex-row p-3">
                  <div className="pt-3">
                    <img
                      src="https://i.ibb.co/mvtmbhP/experience.png"
                      className="w-12 h-12"
                    />
                  </div>
                  <p className="p-3 flex-grow text-xl font-semibold">
                    Experiência
                  </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 p-6">
                  {experiencia === undefined || experiencia === null ? (
                    <p>Adicione aqui suas experiencias profissionais</p>
                  ) : (
                    experiencia.map((exp) => (
                      <div className="flex gap-8">
                        <div className="flex flex-col">
                          <p className="text-md font-semibold">
                            {exp.funcaoexercida} {atualExp(exp.atual)}
                          </p>
                          <small className="text-sm">
                            {exp.nomeempresa} -{" "}
                            {dateExperiencia(
                              exp.dataadmissao,
                              exp.datatermino,
                              exp.atual
                            )}
                          </small>
                          <small className="text-sm"></small>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div className="bg-white">
                <div className="flex flex-row p-3">
                  <img
                    src="https://i.ibb.co/TwpKw14/education.png"
                    className="w-12 h-12"
                  />
                  <p className="flex-grow p-3 text-xl font-semibold">
                    Certificações e/ou cursos
                    <small className="flex text-slate-600 text-sm">
                      Adicione cursos profisionais e certificações.
                    </small>
                  </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 p-6">
                  {curso === undefined ||
                  curso === null ||
                  curso.length === 0 ? (
                    <p>Cursos e certificações do candidato</p>
                  ) : (
                    curso.map((cur) => (
                      <div className="flex gap-8">
                        <div className="flex flex-col">
                          <p className="text-md font-semibold">
                            {cur.nomecurso} {atualCurso(cur.atual)}
                          </p>
                          <small className="text-sm">
                            {cur.localcurso} -{" "}
                            {dateExperiencia(
                              cur.datainiciocurso,
                              cur.datafimcurso,
                              cur.atual
                            )}
                          </small>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div className="bg-white mb-10">
                <div className="flex flex-row p-3">
                  <img
                    src="https://i.ibb.co/c1jTrtY/skills.png"
                    className="w-12 h-12"
                  />
                  <p className="p-3 text-xl font-semibold">
                    {" "}
                    Objetivos
                    <small className="flex text-slate-600 text-sm">
                      Objetivos profissionais do candidato
                    </small>
                  </p>
                </div>

                {user.objetivo != null ? (
                  <div className="gap-4 p-6">{user.objetivo}</div>
                ) : (
                  <div className="gap-4 p-6">....</div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
